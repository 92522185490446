"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextFields = exports.CampaignFields = exports.BackgroundFields = exports.OptionsFields = exports.MainFields = void 0;
const enums_1 = require("../types/enums");
exports.MainFields = {
    name: {
        label: 'Widget Name',
        type: enums_1.FieldType.string
    },
    targetUrl: {
        label: 'Target URL',
        type: enums_1.FieldType.string
    },
    widgetType: {
        label: 'Type',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            sponsored: 'Sponsored'
        }
    },
    sponsorName: {
        label: 'Sponsor Name',
        type: enums_1.FieldType.string
    }
};
exports.OptionsFields = {
    automaticallyScrollProducts: {
        label: 'Automatically Scroll Products',
        type: enums_1.FieldType.switch
    },
    hideNames: {
        label: 'Show Names',
        type: enums_1.FieldType.switch,
        revert: true
    },
    hidePrices: {
        label: 'Show Prices',
        type: enums_1.FieldType.switch,
        revert: true
    },
    showBrand: {
        label: 'Show Brand',
        type: enums_1.FieldType.switch
    },
    darkMode: {
        label: 'Dark Mode',
        type: enums_1.FieldType.switch
    }
};
exports.BackgroundFields = {
    backgroundImageURL: {
        label: 'Main background',
        type: enums_1.FieldType.image,
        isOneInRow: true
        // required: true
    },
    backgroundOverlay: {
        label: 'Background overlay',
        type: enums_1.FieldType.colorPicker,
        classes: 'col-lg-3'
    },
    activateOverlay: {
        label: 'Activate overlay',
        type: enums_1.FieldType.switch,
        classes: 'col-lg-3 mt-5'
    },
    backgroundPosition: {
        label: 'Background Position',
        type: enums_1.FieldType.select,
        isOneInRow: true,
        optionsToSelect: {
            center: 'Center',
            top: 'Top',
            bottom: 'Bottom',
            left: 'Left',
            right: 'Right',
            'right-top': 'Right Top',
            'right-bottom': 'Right Bottom',
            'left-top': 'Left Top',
            'left-bottom': 'Left Bottom'
        }
    },
    backgroundZoom: {
        label: 'Zoom',
        type: enums_1.FieldType.slider,
        isOneInRow: true
    },
    automaticallyScrollProducts: {
        label: 'Use as Mobile background',
        type: enums_1.FieldType.switch,
        classes: 'mt-3',
        isOneInRow: true
    },
    hr: {
        type: enums_1.FieldType.hr
    },
    backgroundImageURLMobile: {
        label: 'Mobile background',
        type: enums_1.FieldType.image,
        isOneInRow: true
    },
    backgroundOverlayMobile: {
        label: 'Background overlay',
        type: enums_1.FieldType.colorPicker,
        classes: 'col-lg-3'
    },
    activateOverlayMobile: {
        label: 'Activate overlay',
        type: enums_1.FieldType.switch,
        classes: 'col-lg-3 mt-5'
    },
    backgroundPositionMobile: {
        label: 'Background Position',
        type: enums_1.FieldType.select,
        isOneInRow: true,
        optionsToSelect: {
            center: 'Center',
            top: 'Top',
            bottom: 'Bottom',
            left: 'Left',
            right: 'Right',
            'right-top': 'Right Top',
            'right-bottom': 'Right Bottom',
            'left-top': 'Left Top',
            'left-bottom': 'Left Bottom'
        }
    },
    backgroundZoomMobile: {
        label: 'Zoom',
        type: enums_1.FieldType.slider,
        isOneInRow: true
    }
};
exports.CampaignFields = {
    // advertiserName: {
    //   label: 'Advertiser/Brand name',
    //   type: FieldType.string,
    //   classes: 'col-lg-4'
    // },
    // clientName: {
    //   label: 'User name',
    //   type: FieldType.string,
    //   requiredUpdate: true,
    //   classes: 'col-lg-4'
    // },
    // email: {
    //   label: 'User email',
    //   type: FieldType.string,
    //   email: true,
    //   requiredUpdate: true,
    //   classes: 'col-lg-4'
    // },
    name: {
        label: 'Campaign Name',
        type: enums_1.FieldType.string,
        classes: 'col-lg-4',
        // required: true
    },
    startDate: {
        label: 'Campaign Start Date',
        type: enums_1.FieldType.dateTime,
        // requiredUpdate: true,
        classes: 'col-lg-4'
    },
    endDate: {
        label: 'Campaign End Date',
        type: enums_1.FieldType.dateTime,
        // requiredUpdate: true,
        classes: 'col-lg-4'
    },
    // budget: {
    //   label: 'Campaign Budget €',
    //   type: FieldType.number,
    //   requiredUpdate: true,
    //   classes: 'col-lg-4'
    // },
    // targetClicks: {
    //   label: 'Targeted Clicks',
    //   type: FieldType.number,
    //   requiredUpdate: true,
    //   classes: 'col-lg-4'
    // },
    // cpc: {
    //   label: 'CPC',
    //   type: FieldType.number,
    //   requiredUpdate: true,
    //   classes: 'col-lg-4'
    // },
    targetGender: {
        label: 'Targeted Gender',
        type: enums_1.FieldType.select,
        multiple: true,
        classes: 'col-lg-4',
        optionsToSelect: {
            men: 'men',
            women: 'women'
        }
    },
    // targetSection: {
    //   label: 'Targeted Section',
    //   // type: FieldType.selectDynamic,
    //   type: FieldType.select,
    //   multiple: true,
    //   classes: 'col-lg-4',
    //   // selectOptionsVariable: {
    //   //   sourceVariable: 'sections',
    //   //   key: 'id',
    //   //   value: 'name'
    //   // }
    //   optionsToSelect: {
    //     news: 'news',
    //     sport: 'sport'
    //   }
    // },
    // targetMedia: {
    //   label: 'Targeted Media',
    //   type: FieldType.select,
    //   // type: FieldType.selectDynamic,
    //   multiple: true,
    //   classes: 'col-lg-4',
    //   // selectOptionsVariable: {
    //   //   sourceVariable: 'medias',
    //   //   key: 'id',
    //   //   value: 'name'
    //   // }
    // },
    // widgetIds: {
    //   label: 'Widgets',
    //   type: FieldType.selectDynamic,
    //   multiple: true,
    //   classes: 'col-lg-4',
    //   selectOptionsVariable: {
    //     sourceVariable: 'widgets',
    //     key: 'id',
    //     value: 'name'
    //   }
    // },
    // missHitLink: {
    //   label: 'Landing Page url',
    //   type: FieldType.string,
    //   description:
    //     'This is where the user will land by clicking on any surface out of the space where the product image is displayed and should include tracking parameters such as utm.',
    //   classes: 'col-lg-12'
    // },
    widgetType: {
        label: 'Widget type',
        type: enums_1.FieldType.select,
        // description: 'It should open up the widget library doc in pdf format',
        classes: 'col-lg-4'
    },
    // title: {
    //   label: 'Widget title',
    //   type: FieldType.string,
    //   description:
    //     'Should appear the max number of hits to include as it is displayed in the widget library doc',
    //   classes: 'col-lg-4'
    // },
    // subtitle: {
    //   label: 'Widget subtitle',
    //   type: FieldType.string,
    //   description:
    //     'Should appear the max number of hits to include as it is displayed in the widget library doc',
    //   classes: 'col-lg-4'
    // },
    // backgroundImageURL: {
    //   label: 'Background image',
    //   type: FieldType.image,
    //   description:
    //     'Should appear the correct size as it is in the widget library doc'
    // },
    // backgroundImageURLMobile: {
    //   label: 'Background image (Mobile)',
    //   type: FieldType.image,
    //   description:
    //     'Should appear the correct size as it is in the widget library doc'
    // },
    // logoImageURL: {
    //   label: 'Brand Logo',
    //   type: FieldType.image,
    //   description:
    //     'Should appear the correct size as it is in the widget library doc'
    // }
};
exports.TextFields = {
    title: {
        label: 'Title',
        type: enums_1.FieldType.string
    },
    subtitle: {
        label: 'Subtitle',
        type: enums_1.FieldType.string
    },
    font: {
        label: 'Font',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            default: 'Default',
            oranienbaum: 'Oranienbaum',
            poppins: 'Poppins',
            gotham: 'Gotham',
            pantexttt: 'PanTextTT',
            'kings-caslon-display': 'KingsCaslonDisplay',
            manrope: 'Manrope',
            lato: 'Lato',
            arial: 'Arial',
            'optimal-el': 'Optimal EL',
            'proxima-nova': 'Proxima Nova',
            montserrat: 'Montserrat',
            seikobernino: 'SeikoBernino'
        }
    },
    titleAlign: {
        label: 'Title Align',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            center: 'Center',
            left: 'Left',
            right: 'Right'
        }
    },
    // TODO: don't have
    fontSize: {
        label: 'Font-size',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            16: '16px',
        }
    },
    // TODO: don't have
    fontColor: {
        label: 'Font color',
        type: enums_1.FieldType.colorPicker,
        classes: 'col-lg-6'
    },
};
