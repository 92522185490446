<template>
  <Field
    v-slot="{ invalid, validated, errorMessage, field }"
    :rules="rules"
    :name="name"
    v-bind="$attrs"
  >
    <div
      class="form-group mb-0"
      :class="{ 'is-invalid': validated && invalid }"
    >
      <slot name="label">
        <!-- <div class="label-container"> -->
        <label
          v-if="label"
          :class="labelClasses"
        >
          {{ rules?.required ? '*' : '' }} {{ label }}
        </label>
        <el-tooltip
          v-if="description"
          placement="top-start"
          effect="light"
        >
          <template #content>
            <!-- eslint-disable vue/no-v-html -->
            <span v-html="description" />
            <!--eslint-enable-->
          </template>
          <i class="fa fa-exclamation-circle ms-1" />
        </el-tooltip>
        <!-- </div> -->
      </slot>
      <!-- <div class="form-group mb-0"> -->

      <div
        :class="[
          { 'input-group': hasIcon },
          { focused: focused },
          { 'has-label': label || $slots.label },
        ]"
      >
        <!-- { 'input-group-alternative': alternative }, -->
        <div
          v-if="prependIcon || $slots.prepend"
          class="input-group-prepend"
        >
          <span class="input-group-text">
            <slot name="prepend">
              <i :class="prependIcon" />
            </slot>
          </span>
        </div>
        <slot v-bind="slotData">
          <!-- <slot> -->
          <!-- v-model="value" -->
          <input
            :type="type"
            :step="step"
            :required="required"
            v-bind="field"
            class="form-control form-control-sm"
            :disabled="disabled"
            :placeholder="placeholder"
            v-on="listeners"
            @keyup="keyup"
          >
          <!-- :name="name"
            :value="value"
            -->
          <!-- :class="[
              { 'is-valid': valid && validated && successMessage },
              { 'is-invalid': invalid && validated },
              inputClasses
            ]" -->

          <!-- :type="type"
            class="form-control form-control-sm"

            :class="getClasses(size, success, error)"
            :name="name"
            :value="value"
            :isRequired="isRequired"
            :disabled="disabled"
            v-bind="field"
            v-on="listeners"
            @change="handleChange" -->
        </slot>
        <div v-if="appendIcon || $slots.append">
          <!-- class="input-group-text p-0" -->
          <span class="input-group-text p-0">
            <slot name="append">
              <!-- <i :class="appendIcon" /> -->
            </slot>
          </span>
        </div>
        <span
          v-if="upload"
          class="input-group-text p-0"
        >

          <input
            ref="fileInput"
            accept="image/*"
            class="file-input"
            type="file"
            @input="onSelectFile"
          >
          <ElTooltip
            v-if="crop && $attrs.modelValue"
            placement="top-start"
            effect="light"
            content="Crop"
          >
            <i
              class="fas fa-crop"
              @click="showCropModal"
            />
          </ElTooltip>
          <ElTooltip
            placement="top-start"
            effect="light"
            content="Choose an Image"
          >
            <i
              class="fas fa-plus-circle"
              @click="chooseFile"
            />
          </ElTooltip>
        </span>
      </div>
      <div
        v-if="errorMessage"
        class="invalid-feedback"
        style="display: block"
      >
        {{ errorMessage }}
      </div>
    </div>
  </Field>
  <ModalImageCrop
    v-if="crop"
    v-model:show-modal="showImageCropModal"
    :originalImageSrc="$attrs.modelValue"
    @crop-image="cropImage($event)"
  ></ModalImageCrop>
</template>

<script>
import { Field } from 'vee-validate'
import { Options, Vue, prop } from 'vue-class-component'
import { ElTooltip } from 'element-plus'
import ModalImageCrop from "@sharedComponents/ModalImageCrop.vue"

class Props {
  size = prop < String > { default: 'default' }
  icon = prop < String > { default: '' }
  iconDir = prop < String > { default: '' }
  name = prop < String > { default: '' }
  value = prop < String > { default: '' }
  labelClasses = prop < String > { default: '' }
  placeholder = prop < String > { default: '' }
  label = prop < String > { default: '' }
  type = prop < String > { default: 'text' }
  required = prop < Boolean > { default: false }
  success = prop < Boolean > { default: false }
  error = prop < Boolean > { default: false }
  upload = prop < Boolean > { default: false }
  crop = prop < Boolean > { default: false }
  disabled = prop < Boolean > { default: false, description: 'Disable input' }
  rules = prop <  Object > { default: '', description: 'Vee validate validation rules' }
  group = prop < Boolean > { default: false, description: 'Whether input is an input group (manual override in special cases)' }

  step = prop < String > { default: '', description: 'step="0.01" for number' }
  appendIcon = prop < String > { default: '', description: 'Append icon (right)' }
  prependIcon = prop < String > { default: '', description: 'Prepend icon (left)' }
  description = prop < String > { default: '', description: 'Input description (text in info icon)' }

}

@Options({
  components: { Field, ElTooltip, ModalImageCrop },
  inheritAttrs: false
})
export default class BaseInput extends Vue.with(Props) {
  focused = false
  showImageCropModal = false

  get listeners() {
    return {
      input: this.updateValue,
      focus: this.onFocus,
      blur: this.onBlur
    }
  }
  get slotData() {
    return {
      focused: this.focused,
      error: this.error,
      ...this.listeners
    }
  }

  getIcon = icon => icon ?? null

  get hasIcon() {
    const { append, prepend } = this.$slots
    return (
      append !== undefined ||
      prepend !== undefined ||
      this.appendIcon !== undefined ||
      this.prependIcon !== undefined ||
      this.group ||
      this.upload
    )
  }

  // getClasses(size, success, error) {
  //   let sizeValue, isValidValue

  //   sizeValue = size ? `form-control-${size}` : null

  //   if (error) {
  //     isValidValue = 'is-invalid'
  //   } else if (success) {
  //     isValidValue = 'is-valid'
  //   } else {
  //     isValidValue = ''
  //   }

  //   return `${sizeValue} ${isValidValue}`
  // }

  chooseFile() {
    const element = this.$refs.fileInput
    element?.click()
  }

  showCropModal() {
    this.showImageCropModal = true
  }

  onSelectFile() {
    const input = this.$refs.fileInput
    const files = input.files
    if (files && files[0]) {
      const reader = new FileReader()
      reader.readAsDataURL(files[0])
      this.$emit('upload', files[0])
    }
  }

  cropImage(image) {
    const reader = new FileReader()
    reader.readAsDataURL(image)
    this.$emit('upload', image)
  }

  updateValue(evt) {
    const value = evt.target.value
    this.$emit('input', value)
  }
  onFocus(evt) {
    this.focused = true
    this.$emit('focus', evt)
  }
  onBlur(evt) {
    this.focused = false
    this.$emit('blur', evt)
  }

  keyup() {
    this.$emit('keyup')
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/_select.scss';
@import '../../assets/styles/_variables.scss';

::v-deep .el-input .el-input__inner {
  display: block;
}

.input-group-text {
  i.fa-plus-circle, i.fa-crop {
    padding: 5px;
    margin: auto;
    font-size: 20px;
    color: $darkturquoise;
    cursor: pointer;
  }
  i.fa-crop {
    color: initial;
  }
  .file-input {
    display: none;
  }
}
</style>
