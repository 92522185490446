"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableOptions = void 0;
const enums_1 = require("../../types/enums");
exports.tableOptions = {
    tableName: 'Targets',
    action: {
        label: 'Add Widget Target',
        route: ''
    },
    operations: {
        save: true,
        delete: true
    },
    columns: {
        name: {
            type: enums_1.FieldType.string,
            label: 'Name',
            width: 290
        },
        startDate: {
            type: enums_1.FieldType.dateTime,
            label: 'Start date',
            width: 160,
            fixedWidth: 160,
            format: "yyyy-MM-dd HH:mm:ss"
        },
        endDate: {
            type: enums_1.FieldType.dateTime,
            label: 'End date',
            width: 160,
            fixedWidth: 160,
            format: "yyyy-MM-dd HH:mm:ss"
        },
        targetImpressions: {
            type: enums_1.FieldType.number,
            label: 'Impressions',
            width: 140,
            // readMode: true
        },
        targetClicks: {
            type: enums_1.FieldType.number,
            label: 'Clicks',
            width: 100
        },
        widgetIds: {
            label: 'Widgets',
            type: enums_1.FieldType.selectDynamic,
            width: 400,
            multiple: true,
            filterByPublisher: true,
            selectOptionsVariable: {
                sourceVariable: 'widgets',
                key: 'id',
                value: 'name'
            }
        },
        publisher: {
            label: 'Publisher',
            type: enums_1.FieldType.selectDynamic,
            width: 120,
            selectOptionsVariable: {
                sourceVariable: 'publishers',
                key: 'id',
                value: 'title'
            }
        },
        campaign: {
            label: 'Campaign',
            type: enums_1.FieldType.selectDynamic,
            width: 320,
            filterByPublisher: true,
            selectOptionsVariable: {
                sourceVariable: 'campaigns',
                key: 'id',
                value: 'name'
            }
        }
    }
};
