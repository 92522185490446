"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableOptions = void 0;
const enums_1 = require("../types/enums");
exports.tableOptions = {
    tableName: 'My Team',
    action: {
        label: 'Add Member',
        route: ''
    },
    operations: {
        save: true,
        edit: true,
        delete: true
    },
    columns: {
        firstname: {
            type: enums_1.FieldType.string,
            label: 'First Name',
            width: 200
        },
        lastname: {
            type: enums_1.FieldType.string,
            label: 'Last Name',
            width: 150
        },
        email: {
            type: enums_1.FieldType.string,
            label: 'Email',
            width: 150
        },
        role: {
            type: enums_1.FieldType.string,
            label: 'Role',
            width: 150
        }
    }
};
