"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_class_component_1 = require("vue-class-component");
const user_1 = require("../../store/user");
class Props {
    constructor() {
        this.videoData = (0, vue_class_component_1.prop)({ type: String, default: '' });
    }
}
let VideoUploadPreview = class VideoUploadPreview extends vue_class_component_1.Vue.with(Props) {
    constructor() {
        super(...arguments);
        this.userStore = (0, user_1.userStore)();
        this.file = false;
    }
    get isAuthorized() {
        return this.userStore.isAuthorized;
    }
    chooseVideo() {
        if (!this.videoData) {
            const element = this.$refs['fileInput'];
            element === null || element === void 0 ? void 0 : element.click();
        }
    }
    viewVideo(e) {
        if (this.videoData) {
            window.open(this.videoData, '_blank');
        }
        e.stopPropagation();
    }
    onSelectFile() {
        const input = this.$refs['fileInput'];
        const files = input.files;
        if (files && files[0]) {
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            this.$emit('upload-file', files[0]);
            this.previewVideo(files[0]);
        }
    }
    deleteVideo(e) {
        this.$emit('delete');
        this.$emit('upload-file', '');
        e.stopPropagation();
    }
    previewVideo(file) {
        const video = document.getElementById('video-preview');
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener('load', function () {
            video.src = reader.result;
        });
        this.file = true;
    }
};
VideoUploadPreview = __decorate([
    (0, vue_class_component_1.Options)({})
], VideoUploadPreview);
exports.default = VideoUploadPreview;
