"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PerformersData = void 0;
exports.PerformersData = [
    {
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        startDate: '2022-05-01',
        endDate: '2022-06-15',
        views: '100',
        ctr: '200',
        clicks: '12345'
    },
    {
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        startDate: '2022-05-01',
        endDate: '2022-06-15',
        views: '100',
        ctr: '200',
        clicks: '12346'
    },
    {
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        startDate: '2022-05-01',
        endDate: '2022-06-15',
        views: '100',
        ctr: '200',
        clicks: '12346'
    },
    {
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        startDate: '2022-05-01',
        endDate: '2022-06-15',
        views: '100',
        ctr: '200',
        clicks: '12346'
    },
    {
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        startDate: '2022-05-01',
        endDate: '2022-06-15',
        views: '100',
        ctr: '200',
        clicks: '12346'
    },
    {
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        startDate: '2022-05-01',
        endDate: '2022-06-15',
        views: '100',
        ctr: '200',
        clicks: '12346'
    },
    {
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        startDate: '2022-05-01',
        endDate: '2022-06-15',
        views: '100',
        ctr: '200',
        clicks: '12346'
    },
    {
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        startDate: '2022-05-01',
        endDate: '2022-06-15',
        views: '100',
        ctr: '200',
        clicks: '12346'
    },
    {
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        startDate: '2022-05-01',
        endDate: '2022-06-15',
        views: '100',
        ctr: '200',
        clicks: '12346'
    },
    {
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        startDate: '2022-05-01',
        endDate: '2022-06-15',
        views: '100',
        ctr: '200',
        clicks: '12346'
    },
    {
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        startDate: '2022-05-01',
        endDate: '2022-06-15',
        views: '100',
        ctr: '200',
        clicks: '12346'
    },
    {
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        startDate: '2022-05-01',
        endDate: '2022-06-15',
        views: '100',
        ctr: '200',
        clicks: '12346'
    },
    {
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        startDate: '2022-05-01',
        endDate: '2022-06-15',
        views: '100',
        ctr: '200',
        clicks: '12346'
    },
    {
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        startDate: '2022-05-01',
        endDate: '2022-06-15',
        views: '100',
        ctr: '200',
        clicks: '12346'
    },
    {
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        startDate: '2022-05-01',
        endDate: '2022-06-15',
        views: '100',
        ctr: '200',
        clicks: '12346'
    },
    {
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        startDate: '2022-05-01',
        endDate: '2022-06-15',
        views: '100',
        ctr: '200',
        clicks: '12346'
    },
    {
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        startDate: '2022-05-01',
        endDate: '2022-06-15',
        views: '100',
        ctr: '200',
        clicks: '12346'
    },
    {
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        startDate: '2022-05-01',
        endDate: '2022-06-15',
        views: '100',
        ctr: '200',
        clicks: '12346'
    },
    {
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        startDate: '2022-05-01',
        endDate: '2022-06-15',
        views: '100',
        ctr: '200',
        clicks: '12346'
    }
];
