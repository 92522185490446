"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableOptions = void 0;
const enums_1 = require("../types/enums");
exports.tableOptions = {
    tableName: 'Best products',
    action: {
        label: 'Add Campaign',
        route: ''
    },
    operations: {
        save: true,
        edit: true,
        delete: true
    },
    columns: {
        product: {
            type: enums_1.FieldType.string,
            label: 'Product Name',
            width: 200
        },
        sponsor: {
            type: enums_1.FieldType.string,
            label: 'Sponsor name',
            width: 150
        },
        widget: {
            type: enums_1.FieldType.string,
            label: 'Widget Name',
            width: 150
        },
        widget_type: {
            type: enums_1.FieldType.string,
            label: 'Widget type',
            width: 150
        },
        clicks: {
            type: enums_1.FieldType.string,
            label: 'Clicks',
            width: 150
        }
    }
};
