<template>
  <div class="custom-control custom-radio d-flex align-items-center">
    <input
      :id="cbId"
      class="custom-control-input"
      type="radio"
      :value="value"
      :checked="modelValue === value"
      @change="onChangeValue"
    >
    <!-- @input="$emit('update:modelValue', $event.target.value)" -->
    <label
      :for="cbId"
      class="custom-control-label mb-0"
    >
      <slot />
    </label>
  </div>
</template>
<script>
import { Options, Vue, prop } from 'vue-class-component'

class Props {
  // name = prop({ type: String, description: 'Radio label' })
  value = prop({ type: String, description: 'Radio label' })
  modelValue = prop({ type: String, description: 'Radio value' })
}

@Options({})
export default class BaseRadio extends Vue.with(Props) {
  cbId = ''

  mounted() {
    this.cbId = Math.random().toString(16).slice(2)
  }

  onChangeValue($event) {
    this.$emit('update:modelValue', $event.target.value)
    this.$emit('on-change')
  }
}
</script>
