"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableOptions = void 0;
const enums_1 = require("../types/enums");
exports.tableOptions = {
    tableName: 'My campaigns',
    action: {
        label: 'Add Campaign',
        route: ''
    },
    operations: {
        save: true,
        edit: true,
        delete: true
    },
    columns: {
        name: {
            type: enums_1.FieldType.string,
            label: 'Name',
            width: 200
        },
        widget: {
            type: enums_1.FieldType.string,
            label: 'Widget',
            width: 150
        },
        startDate: {
            type: enums_1.FieldType.string,
            label: 'Start',
            width: 150
        },
        endDate: {
            type: enums_1.FieldType.string,
            label: 'End',
            width: 150
        },
        views: {
            type: enums_1.FieldType.string,
            label: 'Views',
            width: 150
        },
        clicks: {
            type: enums_1.FieldType.string,
            label: 'Clicks',
            width: 150
        },
        status: {
            type: enums_1.FieldType.string,
            label: 'Status',
            width: 150
        }
    }
};
