"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_class_component_1 = require("vue-class-component");
const element_plus_1 = require("element-plus");
class Props {
    constructor() {
        this.form = (0, vue_class_component_1.prop)({ type: Object, default: '' });
        this.variation = (0, vue_class_component_1.prop)({ type: String, default: '' });
        this.id = (0, vue_class_component_1.prop)({ type: Number });
        this.name = (0, vue_class_component_1.prop)({ type: String, default: '' });
    }
}
let CreativePreviewCard = class CreativePreviewCard extends vue_class_component_1.Vue.with(Props) {
    constructor() {
        super(...arguments);
        this.widthInput = this.baseWidth;
    }
    get host() {
        if (window.location.host.includes('localhost') || window.location.host.includes('127')) {
            return 'http://127.0.0.1:3000';
        }
        if (window.location.host.includes('-qa')) {
            return 'https://widgets-qa.shopcools.it';
        }
        return 'https://widgets.cools.tech';
    }
    get baseWidth() {
        var _a;
        return parseInt((_a = this.form.dimension.split('x')[0]) !== null && _a !== void 0 ? _a : '100');
    }
    get baseHeight() {
        var _a;
        return parseInt((_a = this.form.dimension.split('x')[1]) !== null && _a !== void 0 ? _a : '100');
    }
    get minWidth() {
        return this.baseWidth * 0.75;
    }
    get maxWidth() {
        return this.baseWidth * 2;
    }
    updateWidgetWrapWidth() {
        let widgetWrap = document.getElementById(`draggable-wrap${this.variation}${this.form.device}${this.form.dimension}`);
        if (widgetWrap) {
            widgetWrap.style.width = `${this.widthInput}px`;
        }
    }
    resetWidgetWrapWidth() {
        this.widthInput = this.baseWidth;
    }
};
CreativePreviewCard = __decorate([
    (0, vue_class_component_1.Options)({ components: { ElTooltip: element_plus_1.ElTooltip } })
], CreativePreviewCard);
exports.default = CreativePreviewCard;
