"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.templates = exports.ProductShowType = void 0;
exports.ProductShowType = [
    {
        title: 'Showcase my products',
        src: 'https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/product-page.jpg',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.',
        specifications: [{ label: 'Image', includes: true }, { label: 'Product(s)', includes: true }, { label: 'Text', includes: true }]
    },
    {
        title: 'Have an immersive format',
        src: 'https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/product-page.jpg',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.',
        specifications: [{ label: 'Image', includes: true }, { label: 'Product(s)', includes: true }, { label: 'Text', includes: true }]
    },
    {
        title: 'Balance between brand and products',
        src: 'https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/product-page.jpg',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.',
        specifications: [{ label: 'Image', includes: true }, { label: 'Product(s)', includes: true }, { label: 'Text', includes: true }]
    },
    {
        title: 'Integrate seamlessly in the editorial',
        src: 'https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/product-page.jpg',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.',
        specifications: [{ label: 'Image', includes: true }, { label: 'Product(s)', includes: false }, { label: 'Text', includes: true }]
    }
];
exports.templates = [
    { title: 'Slider', src: '../../../../assets/img/temp/slider_example.jpg' },
    { title: 'Layout', src: '/img/temp/slider_example.jpg' },
    { title: 'Layout Multi', src: '/assets/img/temp/slider_example.jpg' },
    { title: 'Grids', src: '/assets/img/temp/slider_example.jpg' },
    { title: 'Dynamic slider', src: '/assets/img/temp/slider_example.jpg' },
    { title: 'Layout Multi', src: '/assets/img/temp/slider_example.jpg' },
    { title: 'Immersion', src: '/assets/img/temp/slider_example.jpg' },
    { title: 'Overlay', src: '/assets/img/temp/slider_example.jpg' },
    { title: 'Integrated  standard', src: '/assets/img/temp/slider_example.jpg' },
];
