// import GlobalComponents from "../argon-dashboard/plugins/globalComponents";
// import GlobalDirectives from "../argon-dashboard/plugins/globalDirectives";

// import "../argon-dashboard/assets/css/nucleo/css/nucleo.css";
// import "../argon-dashboard/assets/sass/argon.scss";
import "../argon-dashboard/assets/css/nucleo-icons.css"
import "../argon-dashboard/assets/scss/argon-dashboard.scss"
import SidebarPlugin from "../components/SidebarPlugin";

import "bootstrap/dist/js/bootstrap.min.js";

export default {
  install(app) {
    app.use(SidebarPlugin);
    // app.use(GlobalComponents);
    // app.use(GlobalDirectives);
  },
};
