export const diff = function (obj1, obj2, deep = true) {
  // Make sure an object to compare is provided
  if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
    return obj1
  }

  //
  // Variables
  //

  const diffs = {}
  let key

  //
  // Methods
  //
  // const objectsEqual = (o1, o2) =>
  //   Object.keys(o1).length === Object.keys(o2).length &&
  //   Object.keys(o1).every(p => o1[p] === o2[p])

  /**
   * Check if two arrays are equal
   * @param  {Array}   arr1 The first array
   * @param  {Array}   arr2 The second array
   * @return {Boolean}      If true, both arrays are equal
   */
  const arraysMatch = function (arr1, arr2) {
    // Check if the arrays are the same length
    if (arr1.length !== arr2.length) return false

    // Check if all items exist and are in the same order
    for (let i = 0; i < arr1.length; i++) {
      if (Object.prototype.toString.call(arr1[i]) === '[object Object]') {
        // if (!objectsEqual(arr1[i], arr2[i])) {
        //   return false
        // }
        const objDiff = diff(arr1[i], arr2[i], deep)
        if (Object.keys(objDiff).length > 0) {
          diffs[key] = arr2
          return false
        }
        // compare objects
      } else if (arr1[i] !== arr2[i]) {
        return false
      }
    }

    // Otherwise, return true
    return true
  }

  /**
   * Compare two items and push non-matches to object
   * @param  {*}      item1 The first item
   * @param  {*}      item2 The second item
   * @param  {String} key   The key in our object
   */
  const compare = function (item1, item2, key) {
    // Get the object type
    const type1 = Object.prototype.toString.call(item1)
    const type2 = Object.prototype.toString.call(item2)

    // If type2 is undefined it has been removed
    if (type2 === '[object Undefined]') {
      diffs[key] = null
      return
    }

    // If items are different types
    // TODO: Check string='' and type null
    // if (type1 !== type2) {
    //   diffs[key] = item2
    //   return
    // }

    // If an object, compare recursively
    if (type1 === '[object Object]') {
      const objDiff = diff(item1, item2, deep)
      if (Object.keys(objDiff).length > 0) {
        if (deep) {
          diffs[key] = objDiff
        } else {
          diffs[key] = item2
        }
      }
      return
    }

    // If an array, compare
    if (type1 === '[object Array]') {
      if (!arraysMatch(item1, item2)) {
        diffs[key] = item2
      }
      return
    }

    // Else if it's a function, convert to a string and compare
    // Otherwise, just compare
    if (type1 === '[object Function]') {
      if (item1.toString() !== item2.toString()) {
        diffs[key] = item2
      }
    } else if (item1 !== item2 && (!!item1 || !!item2)) {
      diffs[key] = item2
    }
  }

  //
  // Compare our objects
  //

  // Loop through the first object
  for (key in obj1) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj1.hasOwnProperty(key)) {
      compare(obj1[key], obj2[key], key)
    }
  }

  // Loop through the second object and find missing items
  for (key in obj2) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj2.hasOwnProperty(key)) {
      if (!obj1[key] && !!obj2[key] && obj1[key] !== obj2[key]) {
        diffs[key] = obj2[key]
      }
    }
  }

  // Return the object of differences
  return diffs
}
