"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartnerFields = exports.tableOptions = void 0;
const enums_1 = require("../../types/enums");
exports.tableOptions = {
    tableName: 'Partners',
    action: {
        label: 'Add Partner',
        route: '/admin/showcase/partners/create',
        routeEditName: 'Partner Edit'
    },
    operations: {
        save: true,
        edit: true,
        delete: true
    },
    columns: {
        name: {
            type: enums_1.FieldType.string,
            label: 'Name',
            width: 100
        },
        slug: {
            type: enums_1.FieldType.string,
            label: 'Slug',
            width: 100
        },
        logo: {
            type: enums_1.FieldType.image,
            label: 'Logo',
            width: 100,
            fixedWidth: 100
        },
    }
};
exports.PartnerFields = {
    name: {
        label: 'Name',
        type: enums_1.FieldType.string,
        required: true,
    },
    slug: {
        label: 'Slug',
        type: enums_1.FieldType.string,
        required: true,
    },
    logo: {
        label: 'Logo',
        type: enums_1.FieldType.image,
        required: true,
    },
    showcaseIds: {
        label: 'Showcases',
        type: enums_1.FieldType.selectSortableArray,
        multiple: true,
        selectOptionsVariable: {
            sourceVariable: 'showcases',
            key: 'id',
            value: 'name'
        },
    },
};
