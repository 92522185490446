"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateTokenCryptoNumber = void 0;
function generateTokenCryptoNumber(length = 8, alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-') {
    let result = '';
    for (let i = 0; i < length; i++) {
        result += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
    }
    return result;
}
exports.generateTokenCryptoNumber = generateTokenCryptoNumber;
