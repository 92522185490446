"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_class_component_1 = require("vue-class-component");
const element_plus_1 = require("element-plus");
const widget_1 = require("../../../../store/widget");
class Props {
    constructor() {
        this.form = (0, vue_class_component_1.prop)({ type: Object, default: {} });
        this.variation = (0, vue_class_component_1.prop)({ type: String, default: '' });
        this.id = (0, vue_class_component_1.prop)({ type: Number });
        this.name = (0, vue_class_component_1.prop)({ type: String, default: '' });
    }
}
let WidgetPreviewCard = class WidgetPreviewCard extends vue_class_component_1.Vue.with(Props) {
    constructor() {
        super(...arguments);
        this.widgetStore = (0, widget_1.widgetStore)();
        this.widthInput = this.baseWidth;
    }
    get showWidgetPreview() {
        return this.widgetStore.showWidgetPreview;
    }
    get baseWidth() {
        var _a;
        return parseInt((_a = this.form.dimension.split('x')[0]) !== null && _a !== void 0 ? _a : '100');
    }
    get minWidth() {
        return this.baseWidth * 0.75;
    }
    get maxWidth() {
        return this.baseWidth * 2;
    }
    updateWidgetWrapWidth() {
        let widgetWrap = document.getElementById(`draggable-wrap${this.variation}${this.form.device}${this.form.dimension}`);
        if (widgetWrap) {
            widgetWrap.style.width = `${this.widthInput}px`;
        }
    }
    resetWidgetWrapWidth() {
        this.widthInput = this.baseWidth;
    }
};
WidgetPreviewCard = __decorate([
    (0, vue_class_component_1.Options)({ components: { ElTooltip: element_plus_1.ElTooltip } })
], WidgetPreviewCard);
exports.default = WidgetPreviewCard;
