"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductDisplayOptionsFields = exports.ProductFields = void 0;
const enums_1 = require("../types/enums");
exports.ProductFields = {
    productName: {
        label: 'Product name',
        type: enums_1.FieldType.string,
        required: true
    },
    description: {
        label: 'Product description',
        type: enums_1.FieldType.string
    },
    brand: {
        label: 'Brand',
        type: enums_1.FieldType.string,
        required: true
    },
    retailerName: {
        label: 'Retailer name',
        type: enums_1.FieldType.string
    },
    url: {
        label: 'Product url',
        type: enums_1.FieldType.string,
        required: true,
        url: true
    },
    image: {
        label: 'Product image',
        placeholder: 'Product image url',
        type: enums_1.FieldType.string,
        description: 'Should appear the correct size as it is in the widget library doc',
        required: true,
        upload: true,
        url: true
    },
    retailPrice: {
        label: 'Retail price',
        type: enums_1.FieldType.number
    },
    salePrice: {
        label: 'Discount price',
        type: enums_1.FieldType.number
    }
};
exports.ProductDisplayOptionsFields = {
    cropProductsBackground: {
        label: 'Crop Products Background',
        type: enums_1.FieldType.boolean,
        defaultOn: true,
    },
    productImageAlphaMatting: {
        label: 'Product Image Alpha Matting',
        type: enums_1.FieldType.boolean
    },
    productImageErodeSize: {
        label: 'Product Image Erode Size',
        type: enums_1.FieldType.number
    },
    productImageBackgroundThreshold: {
        label: 'Product Image Background Threshold',
        type: enums_1.FieldType.number
    },
    productImageForegroundThreshold: {
        label: 'Product Image Foreground Threshold',
        type: enums_1.FieldType.number
    },
    useRemoveBackgroundAPI: {
        label: 'Use remove.bg API ro remove background',
        type: enums_1.FieldType.boolean
    },
    productImageURL: {
        label: 'Product Image',
        type: enums_1.FieldType.image
    }
};
