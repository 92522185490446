export const widgetFromVariation = function (widgetOriginal, fieldsToUpdate) {
  if (
    !fieldsToUpdate ||
    Object.prototype.toString.call(fieldsToUpdate) !== '[object Object]'
  ) {
    return fieldsToUpdate
  }

  const newWidget = widgetOriginal
  let key

  /**
   * Check if two arrays are equal
   * @param  {Array}   arr1 The first array
   * @param  {Array}   arr2 The second array
   * @return {Boolean}      If true, both arrays are equal
   */
  const arraysMatch = function (arr1, arr2) {
    // Check if the arrays are the same length
    if (arr1.length !== arr2.length) return false
    // Check if all items exist and are in the same order
    for (let i = 0; i < arr1.length; i++) {
      if (Object.prototype.toString.call(arr1[i]) === '[object Object]') {
        const objDiff = widgetFromVariation(arr1[i], arr2[i])
        if (Object.keys(objDiff).length > 0) {
          newWidget[key] = arr2
          return false
        }
      } else if (arr1[i] !== arr2[i]) {
        return false
      }
    }
    return true
  }

  /**
   * Compare two items and push non-matches to object
   * @param  {*}      item1 The first item
   * @param  {*}      item2 The second item
   * @param  {String} key   The key in our object
   */
  const compare = function (item1, item2, key) {
    // Get the object type
    const type1 = Object.prototype.toString.call(item1)
    const type2 = Object.prototype.toString.call(item2)

    // If an object, compare recursively
    if (type2 === '[object Object]') {
      const objDiff = widgetFromVariation(item1, item2)
      if (Object.keys(objDiff).length > 0) {
        newWidget[key] = objDiff
      }
      return
    }

    // If an array, compare
    if (type1 === '[object Array]') {
      if (!arraysMatch(item1, item2)) {
        newWidget[key] = item2
      }
      return
    }

    if (item1 !== item2) {
      newWidget[key] = item2
    }
  }

  //
  // Compare our objects
  //

  // Loop through the first object
  for (key in fieldsToUpdate) {
    // eslint-disable-next-line no-prototype-builtins
    if (widgetOriginal.hasOwnProperty(key)) {
      compare(widgetOriginal[key], fieldsToUpdate[key], key)
    } else {
      // If opened variation has field which doesn't exist in widgetOriginal
      newWidget[key] = fieldsToUpdate[key]
    }
  }

  // Return the object of differences
  return newWidget
}
