"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublisherFields = exports.tableOptions = void 0;
const enums_1 = require("../../types/enums");
exports.tableOptions = {
    tableName: 'Publishers',
    action: {
        label: 'Add Publisher',
        route: '/admin/publishers/new',
        routeEditName: 'Publisher Edit'
    },
    operations: {
        save: true,
        edit: true,
        delete: true
    },
    columns: {
        title: {
            type: enums_1.FieldType.string,
            label: 'Title',
            width: 200
        },
        shopHost: {
            type: enums_1.FieldType.string,
            label: 'Shop host',
            width: 150
        },
        redirectOnly: {
            type: enums_1.FieldType.boolean,
            label: 'Redirect only',
            width: 110
        },
    }
};
exports.PublisherFields = {
    title: {
        label: 'Title',
        type: enums_1.FieldType.string,
        required: true
    },
    shopHost: {
        label: 'Shop host',
        url: true,
        required: true,
        type: enums_1.FieldType.string,
    },
    redirectOnly: {
        label: 'Redirect only',
        type: enums_1.FieldType.boolean,
    },
};
