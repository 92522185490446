<template>
  <div
    class="custom-control custom-checkbox"
    :class="[
      { disabled: disabled },
      { [`custom-checkbox-${type}`]: type },
      inlineClass
    ]"
  >
    <input
      :id="cbId"
      v-model="model"
      class="custom-control-input"
      :aria-label="ariaLabel"
      :class="inputClasses"
      type="checkbox"
      :disabled="disabled"
    >
    <label
      :for="cbId"
      class="custom-control-label"
      :class="labelClasses"
    >
      <slot>
        <span v-if="inline">&nbsp;</span>
      </slot>
    </label>
  </div>
</template>
<script>
export default {
  name: 'BaseCheckboxCustom',
  model: {
    prop: 'checked'
  },
  props: {
    revert: {
      type: Boolean,
      default: false
    },
    checked: {
      default: false,
      type: [Array, Boolean],
      description: 'Whether checkbox is checked'
    },
    disabled: {
      type: Boolean,
      description: 'Whether checkbox is disabled'
    },
    ariaLabel: {
      default: '',
      type: String
    },
    inline: {
      type: Boolean,
      description: 'Whether checkbox is inline'
    },
    inputClasses: {
      default: '',
      type: [String, Object, Array],
      description: 'Checkbox input classes'
    },
    labelClasses: {
      type: String,
      description: 'Input label css classes',
      default: 'form-control-label'
    },
    type: {
      default: '',
      type: String,
      description: 'Checkbox type (e.g info, danger etc)'
    }
  },
  data() {
    return {
      cbId: '',
      touched: false
    }
  },
  computed: {
    model: {
      get() {
        return this.revert ? !this.checked : this.checked
      },
      set(check) {
        if (!this.touched) {
          this.touched = true
        }
        this.$emit('update:modelValue', this.revert ? !check : check)
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    inlineClass() {
      if (this.inline) {
        return `form-check-inline`
      }
    }
  },
  mounted() {
    this.cbId = Math.random().toString(16).slice(2)
  }
}
</script>
<style lang="scss" scoped>
.custom-control-label.isHeader {
  font-size: 17px;
  color: #32325d;
  font-weight: 600;
}
</style>
