"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampaignFields = exports.tableOptions = void 0;
const Campaign_1 = require("../../models/Campaign");
const enums_1 = require("../../types/enums");
exports.tableOptions = {
    tableName: 'Campaigns',
    action: {
        label: 'Add Campaign',
        route: '/admin/campaigns/new',
        routeEditName: 'Widget Campaign Edit'
    },
    operations: {
        save: true,
        edit: true,
        copy: false,
        delete: true
    },
    columns: {
        statuses: {
            type: enums_1.FieldType.dots,
            width: 104,
            fixedWidth: 104,
            sortable: false,
            label: 'Status'
        },
        runningStatus: {
            type: enums_1.FieldType.content,
            width: 18,
            fixedWidth: 18,
            sortable: false,
            label: ''
        },
        name: {
            type: enums_1.FieldType.string,
            label: 'Name',
            width: 410
        },
        lastTargetDate: {
            type: enums_1.FieldType.dateTime,
            label: 'Target Date',
            width: 100,
            format: "yyyy-MM-dd HH:mm:ss"
        },
        costModel: {
            label: 'Cost model',
            type: enums_1.FieldType.select,
            width: 100,
            selectOptions: Campaign_1.CampaignModels.map((x) => ({ key: x, value: x }))
        },
        // validationStatus: {
        //   type: FieldType.string,
        //   label: 'Status',
        //   width: 140,
        //   readMode: true
        // },
        // widgetType: {
        //   type: FieldType.string,
        //   label: 'Widget type',
        //   width: 190,
        //   fixedWidth: 190,
        //   readMode: true
        // },
        // startDate: {
        //   type: FieldType.dateTime,
        //   label: 'Start time',
        //   width: 180,
        //   format: 'yyyy-MM-dd HH:mm:ss'
        // },
        // endDate: {
        //   type: FieldType.dateTime,
        //   label: 'End time',
        //   width: 180,
        //   format: 'yyyy-MM-dd HH:mm:ss'
        // },
        publisher: {
            label: 'Publisher',
            type: enums_1.FieldType.selectDynamic,
            width: 120,
            fixedWidth: 120,
            selectOptionsVariable: {
                sourceVariable: 'publishers',
                key: 'id',
                value: 'title'
            }
        }
    }
};
exports.CampaignFields = {
    name: {
        label: 'Campaign Name',
        type: enums_1.FieldType.string,
        classes: 'col-lg-4',
        required: true
    },
    publisher: {
        label: 'Publisher',
        type: enums_1.FieldType.selectDynamic,
        required: true,
        classes: 'col-lg-4',
        selectOptionsVariable: {
            sourceVariable: 'publishers',
            key: 'id',
            value: 'title'
        }
    },
    costModel: {
        label: 'Cost model',
        type: enums_1.FieldType.select,
        required: true,
        classes: 'col-lg-4',
        optionsToSelect: Campaign_1.CampaignModels.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v]: v })), {})
    },
    cost: {
        label: 'Cost',
        type: enums_1.FieldType.number,
        required: false,
        classes: 'col-lg-4',
    },
    budget: {
        label: 'Budget',
        type: enums_1.FieldType.object,
        subFields: {
            amount: {
                label: 'Amount',
                uniqueName: 'amount',
                type: enums_1.FieldType.number,
                classes: 'col-lg-4',
            },
            currency: {
                label: 'Currency',
                uniqueName: 'currency',
                type: enums_1.FieldType.select,
                classes: 'col-lg-4',
                // selectOptionsVariable: {
                //   sourceVariable: 'currencies',
                //   key: 'key',
                //   value: 'value'
                // }
                optionsToSelect: Campaign_1.Currencies.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v]: v })), {})
            }
            // currency: {
            //   label: 'Currency',
            //   uniqueName: 'currency',
            //   type: FieldType.selectDynamic,
            //   classes: 'col-lg-4',
            //   selectOptionsVariable: {
            //     sourceVariable: 'currencies',
            //     key: 'key',
            //     value: 'value'
            //   }
            // }
        }
    },
    scoreMultiplier: {
        label: 'Priority score multiplier',
        type: enums_1.FieldType.number,
        required: false,
        classes: 'col-lg-4',
    },
};
