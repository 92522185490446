"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampaignBookingValidation = exports.CampaignBookingStatus = exports.FieldType = void 0;
var FieldType;
(function (FieldType) {
    FieldType["string"] = "string";
    FieldType["select"] = "select";
    FieldType["selectDynamic"] = "selectDynamic";
    FieldType["boolean"] = "boolean";
    FieldType["switch"] = "switch";
    FieldType["dateTime"] = "dateTime";
    FieldType["number"] = "number";
    FieldType["array"] = "array";
    FieldType["object"] = "object";
    FieldType["slider"] = "slider";
    FieldType["colorPicker"] = "colorPicker";
    FieldType["image"] = "image";
    FieldType["video"] = "video";
    FieldType["hr"] = "hr";
    FieldType["content"] = "content";
    FieldType["dots"] = "dots";
    FieldType["text"] = "text";
    FieldType["arrayCard"] = "arrayCard";
    FieldType["selectSortableArray"] = "selectSortableArray";
    FieldType["cascader"] = "cascader";
    // week = 'week'
})(FieldType = exports.FieldType || (exports.FieldType = {}));
var CampaignBookingStatus;
(function (CampaignBookingStatus) {
    CampaignBookingStatus["draft"] = "draft";
    CampaignBookingStatus["processing"] = "processing";
    CampaignBookingStatus["validating"] = "validating";
    CampaignBookingStatus["pending"] = "pending";
    CampaignBookingStatus["done"] = "done";
})(CampaignBookingStatus = exports.CampaignBookingStatus || (exports.CampaignBookingStatus = {}));
var CampaignBookingValidation;
(function (CampaignBookingValidation) {
    CampaignBookingValidation["notValidated"] = "not_validated";
    CampaignBookingValidation["mobile"] = "mobile";
    CampaignBookingValidation["desktop"] = "desktop";
    CampaignBookingValidation["validated"] = "validated";
})(CampaignBookingValidation = exports.CampaignBookingValidation || (exports.CampaignBookingValidation = {}));
