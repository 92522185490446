"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.campaignsData = void 0;
exports.campaignsData = [
    {
        name: 'XXX_123_ABC',
        widget: 'Slider',
        startDate: '2022-05-01',
        endDate: '2022-06-15',
        views: '1234567',
        clicks: '12345',
        status: 'Active'
    },
    {
        name: 'XXX_456_DEF',
        widget: 'Slider',
        startDate: '2022-05-02',
        endDate: '2022-06-16',
        views: '1234',
        clicks: '12346',
        status: 'Active'
    },
    {
        name: 'XXX_456_DEF',
        widget: 'Slider',
        startDate: '2022-05-02',
        endDate: '2022-06-16',
        views: '1234',
        clicks: '12346',
        status: 'Active'
    },
    {
        name: 'XXX_456_DEF',
        widget: 'Slider',
        startDate: '2022-05-02',
        endDate: '2022-06-16',
        views: '1234',
        clicks: '12346',
        status: 'Active'
    },
    {
        name: 'XXX_456_DEF',
        widget: 'Slider',
        startDate: '2022-05-02',
        endDate: '2022-06-16',
        views: '1234',
        clicks: '12346',
        status: 'Active'
    },
    {
        name: 'XXX_456_DEF',
        widget: 'Slider',
        startDate: '2022-05-02',
        endDate: '2022-06-16',
        views: '1234',
        clicks: '12346',
        status: 'Active'
    },
    {
        name: 'XXX_456_DEF',
        widget: 'Slider',
        startDate: '2022-05-02',
        endDate: '2022-06-16',
        views: '1234',
        clicks: '12346',
        status: 'Active'
    },
    {
        name: 'XXX_456_DEF',
        widget: 'Slider',
        startDate: '2022-05-02',
        endDate: '2022-06-16',
        views: '1234',
        clicks: '12346',
        status: 'Active'
    },
    {
        name: 'XXX_456_DEF',
        widget: 'Slider',
        startDate: '2022-05-02',
        endDate: '2022-06-16',
        views: '1234',
        clicks: '12346',
        status: 'Active'
    },
    {
        name: 'XXX_456_DEF',
        widget: 'Slider',
        startDate: '2022-05-02',
        endDate: '2022-06-16',
        views: '1234',
        clicks: '12346',
        status: 'Active'
    },
    {
        name: 'XXX_456_DEF',
        widget: 'Slider',
        startDate: '2022-05-02',
        endDate: '2022-06-16',
        views: '1234',
        clicks: '12346',
        status: 'Active'
    },
    {
        name: 'XXX_456_DEF',
        widget: 'Slider',
        startDate: '2022-05-02',
        endDate: '2022-06-16',
        views: '1234',
        clicks: '12346',
        status: 'Active'
    },
    {
        name: 'XXX_456_DEF',
        widget: 'Slider',
        startDate: '2022-05-02',
        endDate: '2022-06-16',
        views: '1234',
        clicks: '12346',
        status: 'Active'
    },
    {
        name: 'XXX_456_DEF',
        widget: 'Slider',
        startDate: '2022-05-02',
        endDate: '2022-06-16',
        views: '1234',
        clicks: '12346',
        status: 'Active'
    },
    {
        name: 'XXX_456_DEF',
        widget: 'Slider',
        startDate: '2022-05-02',
        endDate: '2022-06-16',
        views: '1234',
        clicks: '12346',
        status: 'Active'
    },
    {
        name: 'XXX_456_DEF',
        widget: 'Slider',
        startDate: '2022-05-02',
        endDate: '2022-06-16',
        views: '1234',
        clicks: '12346',
        status: 'Active'
    },
    {
        name: 'XXX_456_DEF',
        widget: 'Slider',
        startDate: '2022-05-02',
        endDate: '2022-06-16',
        views: '1234',
        clicks: '12346',
        status: 'Active'
    },
    {
        name: 'XXX_456_DEF',
        widget: 'Slider',
        startDate: '2022-05-02',
        endDate: '2022-06-16',
        views: '1234',
        clicks: '12346',
        status: 'Active'
    },
    {
        name: 'XXX_456_DEF',
        widget: 'Slider',
        startDate: '2022-05-02',
        endDate: '2022-06-16',
        views: '1234',
        clicks: '12346',
        status: 'Active'
    },
];
