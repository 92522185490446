"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampaignFields = exports.tableOptions = void 0;
const enums_1 = require("../../types/enums");
exports.tableOptions = {
    tableName: 'Campaign Bookings',
    action: {
        label: 'Add Campaign Booking',
        route: '/admin/campaign_bookings/new',
        copy: "Copy invitation link",
        routeEditName: 'Widget Campaign Bookings Edit'
    },
    operations: {
        save: true,
        edit: true,
        copy: true,
        delete: true
    },
    columns: {
        name: {
            type: enums_1.FieldType.string,
            label: 'Name',
            width: 410
        },
        validationStatus: {
            type: enums_1.FieldType.string,
            label: "Status",
            width: 140,
            readMode: true
        },
        widgetType: {
            type: enums_1.FieldType.string,
            label: 'Widget type',
            width: 190,
            fixedWidth: 190,
            readMode: true
        },
        startDate: {
            type: enums_1.FieldType.dateTime,
            label: 'Start time',
            width: 180,
            format: "yyyy-MM-dd HH:mm:ss"
        },
        endDate: {
            type: enums_1.FieldType.dateTime,
            label: 'End time',
            width: 180,
            format: "yyyy-MM-dd HH:mm:ss"
        },
        publisher: {
            label: 'Publisher',
            type: enums_1.FieldType.selectDynamic,
            width: 120,
            fixedWidth: 120,
            selectOptionsVariable: {
                sourceVariable: 'publishers',
                key: 'id',
                value: 'title'
            }
        }
    }
};
exports.CampaignFields = {
    advertiserName: {
        label: 'Advertiser/Brand name',
        type: enums_1.FieldType.string,
        classes: 'col-lg-4'
    },
    clientName: {
        label: 'User name',
        type: enums_1.FieldType.string,
        requiredUpdate: true,
        classes: 'col-lg-4'
    },
    email: {
        label: 'User email',
        type: enums_1.FieldType.string,
        email: true,
        requiredUpdate: true,
        classes: 'col-lg-4'
    },
    name: {
        label: 'Campaign Name',
        type: enums_1.FieldType.string,
        classes: 'col-lg-4',
        required: true
    },
    startDate: {
        label: 'Campaign Start Date',
        type: enums_1.FieldType.dateTime,
        requiredUpdate: true,
        classes: 'col-lg-4'
    },
    endDate: {
        label: 'Campaign End Date',
        type: enums_1.FieldType.dateTime,
        requiredUpdate: true,
        classes: 'col-lg-4'
    },
    budget: {
        label: 'Campaign Budget €',
        type: enums_1.FieldType.number,
        requiredUpdate: true,
        classes: 'col-lg-4'
    },
    targetClicks: {
        label: 'Targeted Clicks',
        type: enums_1.FieldType.number,
        requiredUpdate: true,
        classes: 'col-lg-4'
    },
    cpc: {
        label: 'CPC',
        type: enums_1.FieldType.number,
        requiredUpdate: true,
        classes: 'col-lg-4'
    },
    targetGender: {
        label: 'Targeted Gender',
        type: enums_1.FieldType.select,
        multiple: true,
        classes: 'col-lg-4',
        optionsToSelect: {
            men: 'men',
            women: 'women'
        }
    },
    targetSection: {
        label: 'Targeted Section',
        type: enums_1.FieldType.selectDynamic,
        multiple: true,
        classes: 'col-lg-4',
        selectOptionsVariable: {
            sourceVariable: 'sections',
            key: 'id',
            value: 'name'
        }
    },
    targetMedia: {
        label: 'Targeted Media',
        type: enums_1.FieldType.selectDynamic,
        multiple: true,
        classes: 'col-lg-4',
        selectOptionsVariable: {
            sourceVariable: 'medias',
            key: 'id',
            value: 'name'
        }
    },
    widgetIds: {
        label: 'Widgets',
        type: enums_1.FieldType.selectDynamic,
        multiple: true,
        classes: 'col-lg-4',
        selectOptionsVariable: {
            sourceVariable: 'widgets',
            key: 'id',
            value: 'name'
        }
    },
    publisher: {
        label: 'Publisher',
        type: enums_1.FieldType.selectDynamic,
        required: true,
        classes: 'col-lg-4',
        selectOptionsVariable: {
            sourceVariable: 'publishers',
            key: 'id',
            value: 'title'
        }
    },
    missHitLink: {
        label: 'Landing Page url',
        type: enums_1.FieldType.string,
        description: 'This is where the user will land by clicking on any surface out of the space where the product image is displayed and should include tracking parameters such as utm.',
        classes: 'col-lg-12'
    },
    widgetType: {
        label: 'Widget type',
        type: enums_1.FieldType.select,
        description: 'It should open up the widget library doc in pdf format',
        classes: 'col-lg-4'
    },
    title: {
        label: 'Widget title',
        type: enums_1.FieldType.string,
        description: 'Should appear the max number of hits to include as it is displayed in the widget library doc',
        classes: 'col-lg-4'
    },
    subtitle: {
        label: 'Widget subtitle',
        type: enums_1.FieldType.string,
        description: 'Should appear the max number of hits to include as it is displayed in the widget library doc',
        classes: 'col-lg-4'
    },
    backgroundImageURL: {
        label: 'Background image',
        type: enums_1.FieldType.image,
        description: 'Should appear the correct size as it is in the widget library doc'
    },
    backgroundImageURLMobile: {
        label: 'Background image (Mobile)',
        type: enums_1.FieldType.image,
        description: 'Should appear the correct size as it is in the widget library doc'
    },
    logoImageURL: {
        label: 'Brand Logo',
        type: enums_1.FieldType.image,
        description: 'Should appear the correct size as it is in the widget library doc'
    }
};
