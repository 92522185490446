<template>
  <div
    v-show="show"
    class="modal fade"
    :class="[
      { 'show d-block': show },
      { 'd-none': !show },
      { 'modal-mini': type === 'mini' }
    ]"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!show"
    @mousedown.self="closeModal"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      :class="[
        { 'modal-notice': type === 'notice', [`modal-${size}`]: size },
        modalClasses
      ]"
    >
      <div
        class="modal-content"
        :class="[
          gradient ? `bg-gradient-${gradient}` : '',
          modalContentClasses
        ]"
      >
        <div
          v-if="$slots.header"
          class="modal-header"
          :class="[headerClasses]"
        >
          <slot name="header" />
          <slot name="close-button">
            <button
              v-if="showClose"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="closeModal"
            >
              <span :aria-hidden="!show">×</span>
            </button>
          </slot>
        </div>

        <div
          class="modal-body"
          :class="bodyClasses"
        >
          <slot />
        </div>

        <div
          v-if="$slots.footer"
          class="modal-footer"
          :class="footerClasses"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Modal',
  props: {
    show: Boolean,
    showClose: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: '',
      validator(value) {
        const acceptedValues = ['', 'notice', 'mini']
        return acceptedValues.includes(value)
      },
      description: 'Modal type (notice|mini|"") '
    },
    // eslint-disable-next-line vue/require-default-prop
    modalClasses: {
      type: [Object, String],
      description: 'Modal dialog css classes'
    },
    // eslint-disable-next-line vue/require-default-prop
    size: {
      type: String,
      description: 'Modal size',
      validator(value) {
        const acceptedValues = ['', 'sm', 'lg']
        return acceptedValues.includes(value)
      }
    },
    // eslint-disable-next-line vue/require-default-prop
    modalContentClasses: {
      type: [Object, String],
      description: 'Modal dialog content css classes'
    },
    // eslint-disable-next-line vue/require-default-prop
    gradient: {
      type: String,
      description: 'Modal gradient type (danger, primary etc)'
    },
    // eslint-disable-next-line vue/require-default-prop
    headerClasses: {
      type: [Object, String],
      description: 'Modal Header css classes'
    },
    // eslint-disable-next-line vue/require-default-prop
    bodyClasses: {
      type: [Object, String],
      description: 'Modal Body css classes'
    },
    // eslint-disable-next-line vue/require-default-prop
    footerClasses: {
      type: [Object, String],
      description: 'Modal Footer css classes'
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: 'Modal transition duration'
    }
  },
  emits: ['close'],
  watch: {
    show(val) {
      const documentClasses = document.body.classList
      if (val) {
        documentClasses.add('modal-open')
      } else {
        documentClasses.remove('modal-open')
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>
<style>
.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
