"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowcaseFields = exports.tableOptions = void 0;
const enums_1 = require("../../types/enums");
exports.tableOptions = {
    tableName: 'Showcases',
    action: {
        label: 'Add Showcase',
        route: '/admin/showcase/showcases/new',
        routeEditName: 'Showcase Edit'
    },
    operations: {
        save: true,
        edit: true,
        delete: true,
    },
    columns: {
        status: {
            type: enums_1.FieldType.boolean,
            label: 'Status',
            width: 35,
        },
        name: {
            type: enums_1.FieldType.string,
            label: 'Name',
            width: 100
        },
        slug: {
            type: enums_1.FieldType.string,
            label: 'Slug',
            width: 100
        },
        image: {
            type: enums_1.FieldType.image,
            label: 'Image',
            width: 100,
            fixedWidth: 100
        },
    }
};
exports.ShowcaseFields = {
    status: {
        label: "Status",
        type: enums_1.FieldType.boolean,
        defaultOn: true
    },
    name: {
        label: "Name",
        type: enums_1.FieldType.string,
        required: true,
    },
    slug: {
        label: "Slug",
        type: enums_1.FieldType.string,
        required: true,
    },
    image: {
        label: "Image",
        type: enums_1.FieldType.image,
        required: true,
    },
    items: {
        label: "Items",
        type: enums_1.FieldType.arrayCard,
        subFields: {
            id: {
                label: "ID",
                type: enums_1.FieldType.string,
                disabled: true
            },
            name: {
                label: "Name",
                type: enums_1.FieldType.string,
            },
            slug: {
                label: "Slug",
                type: enums_1.FieldType.string,
            },
            tags: {
                label: "Tags",
                type: enums_1.FieldType.selectDynamic,
                multiple: true,
                selectOptionsVariable: {
                    sourceVariable: 'tags',
                    key: 'id',
                    value: 'name'
                }
            },
            mobileWidget: {
                label: 'Mobile Form',
                type: enums_1.FieldType.cascader,
                selectOptionsVariable: {
                    sourceVariable: 'cascaderOptions',
                },
                isOneInRow: true,
                classes: "col-lg-12"
            },
            desktopWidget: {
                label: 'Desktop Form',
                type: enums_1.FieldType.cascader,
                selectOptionsVariable: {
                    sourceVariable: 'cascaderOptions',
                },
                isOneInRow: true,
                classes: "col-lg-12"
            },
        }
    }
};
