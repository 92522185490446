"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_class_component_1 = require("vue-class-component");
const element_plus_1 = require("element-plus");
class Props {
    constructor() {
        this.id = (0, vue_class_component_1.prop)({ type: String, default: '' });
        this.show = (0, vue_class_component_1.prop)({ type: Boolean, default: false });
    }
}
let OpenWidgetChart = class OpenWidgetChart extends vue_class_component_1.Vue.with(Props) {
};
OpenWidgetChart = __decorate([
    (0, vue_class_component_1.Options)({
        components: {
            ElTooltip: element_plus_1.ElTooltip
        }
    })
], OpenWidgetChart);
exports.default = OpenWidgetChart;
