<template>
<!--  <base-nav-->
<!--    container-classes="container-fluid"-->
<!--    class="navbar-top border-bottom navbar-expand bg-main navbar-dark"-->
<!--  >-->
<!--    &lt;!&ndash; Search form &ndash;&gt;-->
<!--    <form-->
<!--      id="navbar-search-main"-->
<!--      class="navbar-search form-inline mr-sm-3 navbar-search-light"-->
<!--    >-->
<!--      <div class="form-group mb-0">-->
<!--        <div class="input-group input-group-alternative input-group-merge">-->
<!--          <div class="input-group-prepend">-->
<!--            <span class="input-group-text"><i class="fas fa-search" /></span>-->
<!--          </div>-->
<!--          <input-->
<!--            v-model="searchFilterText"-->
<!--            class="form-control"-->
<!--            placeholder="Search"-->
<!--            type="text"-->
<!--            @keyup="filterText"-->
<!--          >-->
<!--        </div>-->
<!--      </div>-->
<!--    </form>-->
<!--    &lt;!&ndash; Navbar links &ndash;&gt;-->
<!--    <ul class="navbar-nav align-items-center ml-md-auto" />-->
<!--    <ul class="navbar-nav align-items-center ml-auto ml-md-0">-->
<!--      <base-dropdown-->
<!--        menu-on-right-->
<!--        class="nav-item"-->
<!--        tag="li"-->
<!--        title-tag="a"-->
<!--        title-classes="nav-link pr-0"-->
<!--      >-->
<!--        <template #title-container>-->
<!--          <a-->
<!--            href="#"-->
<!--            class="nav-link pr-0"-->
<!--            @click.prevent-->
<!--          >-->
<!--            <div class="media align-items-center">-->
<!--              <span class="avatar avatar-sm rounded-circle">-->
<!--                <i class="fas fa-user-plus user-icon" />-->
<!--              </span>-->
<!--              <div class="media-body ml-2 d-none d-lg-block">-->
<!--                <span class="mb-0 text-sm font-weight-bold">Admin</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </a>-->
<!--        </template>-->

<!--        <div class="dropdown-header noti-title">-->
<!--          <h6 class="text-overflow m-0">-->
<!--            Welcome!-->
<!--          </h6>-->
<!--        </div>-->
<!--        <a-->
<!--          href="#!"-->
<!--          class="dropdown-item"-->
<!--        >-->
<!--          <i class="ni ni-single-02" />-->
<!--          <span>My profile</span>-->
<!--        </a>-->
<!--        <a-->
<!--          href="#!"-->
<!--          class="dropdown-item"-->
<!--        >-->
<!--          <i class="ni ni-settings-gear-65" />-->
<!--          <span>Settings</span>-->
<!--        </a>-->
<!--        <a-->
<!--          href="#!"-->
<!--          class="dropdown-item"-->
<!--        >-->
<!--          <i class="ni ni-calendar-grid-58" />-->
<!--          <span>Activity</span>-->
<!--        </a>-->
<!--        <a-->
<!--          href="#!"-->
<!--          class="dropdown-item"-->
<!--        >-->
<!--          <i class="ni ni-support-16" />-->
<!--          <span>Support</span>-->
<!--        </a>-->
<!--        <div class="dropdown-divider" />-->
<!--        <a-->
<!--          href="#!"-->
<!--          class="dropdown-item"-->
<!--        >-->
<!--          <i class="ni ni-user-run" />-->
<!--          <span>Logout</span>-->
<!--        </a>-->
<!--      </base-dropdown>-->
<!--    </ul>-->
<!--  </base-nav>-->
</template>

<script>
// import BaseNav from '../argon-dashboard/components/Navbar/BaseNav'

export default {
  components: {
    // BaseNav
  },

  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchFilterText: '',
      searchQuery: ''
    }
  },
  computed: {
    routeName() {
      const { name } = this.$route
      return this.capitalizeFirstLetter(name)
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications
    },
    closeDropDown() {
      this.activeNotifications = false
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false)
    },
    // FIXME: Urgent. Check filter
    filterText() {
      this.$store.commit(
        'filter/setFilter',
        this.searchFilterText.toLowerCase()
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  background-color: transparent;
}
.user-icon {
  font-size: 15px;
  color: #84dcc6;
}
</style>
