<template>
  <table class="table table-bordered table-striped">
    <thead>
      <tr>
        <th
          v-for="field in fields"
          :key="field"
          @click="sortTable(field)"
        >
          {{ field }} <i
            class="bi bi-sort-alpha-down"
            aria-label="Sort Icon"
          />
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, index) in data"
        :key="index"
      >
        <slot
          :row="item"
          :index="index"
        >
          <td
            v-for="(field, i) in fields"
            :key="i"
          >
            {{ getValue(item, field) }}
          </td>
        </slot>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {prop, Vue} from "vue-class-component";

class Props {
  class = prop < String > {default: ''}
  fields = prop < [] > {default: []}
  data = prop < [] > {default: []}
}

export default class BaseTable extends Vue.with(Props) {
  getValue(item, field) {
    return item[field.toLowerCase().replace(' ', '')]
  }
}
</script>

<style scoped>

</style>
