"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableOptions = void 0;
const enums_1 = require("../../types/enums");
exports.tableOptions = {
    tableName: 'Partner Sections',
    action: {
        label: 'Add Partner',
        route: ''
    },
    operations: {
        save: true,
        delete: true
    },
    columns: {
        id: {
            type: enums_1.FieldType.string,
            label: 'Id',
            width: 55,
            readMode: true
        },
        name: {
            type: enums_1.FieldType.string,
            label: 'Name',
            width: 200
        },
        mapsTo: {
            type: enums_1.FieldType.selectDynamic,
            label: 'Section',
            width: 200,
            selectOptionsVariable: {
                sourceVariable: 'sections',
                key: 'id',
                value: 'name'
            }
        },
        publisher: {
            label: 'Publisher',
            type: enums_1.FieldType.selectDynamic,
            width: 110,
            selectOptionsVariable: {
                sourceVariable: 'publishers',
                key: 'id',
                value: 'title'
            }
        }
    }
};
