"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const pinia_1 = require("pinia");
const useDef = (0, pinia_1.defineStore)('wizard', () => {
    const activeStep = (0, vue_1.ref)(0);
    const productNeed = (0, vue_1.ref)('');
    const productShowType = (0, vue_1.ref)('');
    const widgetType = (0, vue_1.ref)('');
    const showWidgetPreview = (0, vue_1.ref)(true);
    return {
        activeStep,
        productNeed,
        productShowType,
        widgetType,
        showWidgetPreview,
        setInitialStep() {
            return __awaiter(this, void 0, void 0, function* () {
                activeStep.value = 0;
                this.setProductNeed('');
            });
        },
        nextStep() {
            return __awaiter(this, void 0, void 0, function* () {
                activeStep.value++;
            });
        },
        prevStep() {
            return __awaiter(this, void 0, void 0, function* () {
                activeStep.value--;
            });
        },
        setProductNeed(data) {
            return __awaiter(this, void 0, void 0, function* () {
                productNeed.value = data;
            });
        },
        setProductShowType(type) {
            return __awaiter(this, void 0, void 0, function* () {
                productShowType.value = type;
            });
        },
        setTemplate(template) {
            return __awaiter(this, void 0, void 0, function* () {
                widgetType.value = template;
            });
        },
        setShowWidgetPreview(data) {
            return __awaiter(this, void 0, void 0, function* () {
                showWidgetPreview.value = data;
            });
        },
        refreshWidgetPreview() {
            return __awaiter(this, void 0, void 0, function* () {
                this.setShowWidgetPreview(false);
                yield setTimeout(() => {
                    this.setShowWidgetPreview(true);
                }, 0);
            });
        },
    };
});
const useStore = () => useDef();
exports.default = useStore;
