"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.siteTreeTableOptions = void 0;
const enums_1 = require("../types/enums");
exports.siteTreeTableOptions = {
    tableName: 'Site Tree',
    action: {
        label: 'Edit',
        route: ''
    },
    operations: {
        save: false,
        edit: false,
        delete: false
    },
    columns: {
        name: {
            type: enums_1.FieldType.string,
            label: 'Name',
            width: 200
        },
        slug: {
            type: enums_1.FieldType.string,
            label: 'Slug',
            width: 150
        },
        category: {
            type: enums_1.FieldType.string,
            label: 'Category',
            width: 150
        }
    }
};
