"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VirtualProductFields = exports.tableOptions = void 0;
const enums_1 = require("../../types/enums");
exports.tableOptions = {
    tableName: 'Virtual Products',
    action: {
        label: 'Add Virtual Product',
        route: '/admin/virtual_products/new',
        routeEditName: 'Virtual Products Edit',
        routeDuplicateName: 'Virtual Products Copy'
    },
    operations: {
        save: true,
        edit: true,
        duplicate: true,
        delete: true
    },
    columns: {
        image: {
            type: enums_1.FieldType.image,
            label: 'Image',
            width: 100
        },
        productName: {
            type: enums_1.FieldType.string,
            label: 'Product name',
            width: 200
        },
        description: {
            type: enums_1.FieldType.string,
            label: 'Product description',
            fixedWidth: 190,
            width: 150
        },
        brand: {
            type: enums_1.FieldType.string,
            label: 'Brand',
            width: 100
        },
        retailerName: {
            type: enums_1.FieldType.string,
            label: 'Retailer name',
            fixedWidth: 150,
            width: 110
        },
        salePrice: {
            type: enums_1.FieldType.number,
            label: 'Discount price',
            fixedWidth: 150,
            width: 110
        },
        retailPrice: {
            type: enums_1.FieldType.number,
            label: 'Retail price',
            fixedWidth: 130,
            width: 100
        },
        publisher: {
            label: 'Publisher',
            type: enums_1.FieldType.selectDynamic,
            width: 100,
            fixedWidth: 120,
            selectOptionsVariable: {
                sourceVariable: 'publishers',
                key: 'id',
                value: 'title'
            },
            readMode: true
        },
    }
};
exports.VirtualProductFields = {
    productName: {
        label: 'Product name',
        type: enums_1.FieldType.string,
        required: true
    },
    description: {
        label: 'Product description',
        type: enums_1.FieldType.string
    },
    brand: {
        label: 'Brand',
        type: enums_1.FieldType.string,
        required: true
    },
    retailerName: {
        label: 'Retailer name',
        type: enums_1.FieldType.string
    },
    url: {
        label: 'Product url',
        type: enums_1.FieldType.string,
        required: true,
        url: true
    },
    image: {
        label: 'Product image',
        placeholder: 'Product image url',
        type: enums_1.FieldType.string,
        required: true,
        upload: true,
        crop: true,
        url: true
    },
    retailPrice: {
        label: 'Retail price',
        type: enums_1.FieldType.number,
        step: '0.01'
    },
    salePrice: {
        label: 'Discount price',
        type: enums_1.FieldType.number,
        step: '0.01'
    },
    publisher: {
        label: 'Publisher',
        type: enums_1.FieldType.selectDynamic,
        required: true,
        selectOptionsVariable: {
            sourceVariable: 'publishers',
            key: 'id',
            value: 'title'
        }
    }
};
