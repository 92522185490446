"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_class_component_1 = require("vue-class-component");
class Props {
    constructor() {
        this.pageCount = (0, vue_class_component_1.prop)({ type: Number, default: 0 });
        this.perPage = (0, vue_class_component_1.prop)({ type: Number, default: 10 });
        this.total = (0, vue_class_component_1.prop)({ type: Number, default: 0 });
        this.modelValue = (0, vue_class_component_1.prop)({ type: Number, default: 1 });
        this.size = (0, vue_class_component_1.prop)({ type: String, default: '' });
        this.align = (0, vue_class_component_1.prop)({ type: String, default: '' });
    }
}
let BasePagination = class BasePagination extends vue_class_component_1.Vue.with(Props) {
    constructor() {
        super(...arguments);
        this.defaultPagesToDisplay = 5;
    }
    get totalPages() {
        if (this.pageCount > 0)
            return this.pageCount;
        if (this.total > 0) {
            return Math.ceil(this.total / this.perPage);
        }
        return 1;
    }
    get pagesToDisplay() {
        if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
            return this.totalPages;
        }
        return this.defaultPagesToDisplay;
    }
    get minPage() {
        if (this.modelValue >= this.pagesToDisplay) {
            const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
            const newMaxPage = pagesToAdd + this.modelValue;
            if (newMaxPage > this.totalPages) {
                return this.totalPages - this.pagesToDisplay + 1;
            }
            return this.modelValue - pagesToAdd;
        }
        else {
            return 1;
        }
    }
    get maxPage() {
        if (this.modelValue >= this.pagesToDisplay) {
            const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
            const newMaxPage = pagesToAdd + this.modelValue;
            if (newMaxPage < this.totalPages) {
                return newMaxPage;
            }
            else {
                return this.totalPages;
            }
        }
        else {
            return this.pagesToDisplay;
        }
    }
    range(min, max) {
        const arr = [];
        for (let i = min; i <= max; i++) {
            arr.push(i);
        }
        return arr;
    }
    changePage(item) {
        this.$emit('update:modelValue', item);
    }
    nextPage() {
        if (this.modelValue < this.totalPages) {
            this.$emit('update:modelValue', this.modelValue + 1);
        }
    }
    prevPage() {
        if (this.modelValue > 1) {
            this.$emit('update:modelValue', this.modelValue - 1);
        }
    }
};
BasePagination = __decorate([
    (0, vue_class_component_1.Options)({})
], BasePagination);
exports.default = BasePagination;
