"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.userStore = void 0;
const pinia_1 = require("pinia");
const element_plus_1 = require("element-plus");
const Shared_1 = require("../../../Shared");
exports.userStore = (0, pinia_1.defineStore)('user', {
    state: () => ({
        _currentUser: undefined
    }),
    getters: {
        currentUser(state) {
            return state._currentUser;
        },
        isAuthorized(state) {
            var _a;
            return !!((_a = state._currentUser) === null || _a === void 0 ? void 0 : _a.id);
        }
    },
    actions: {
        fetchCurrentUser() {
            var _a;
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    this._currentUser = yield Shared_1.AuthService.userInfo();
                }
                catch (e) {
                    element_plus_1.ElNotification.error((_a = e === null || e === void 0 ? void 0 : e.response.data.message) !== null && _a !== void 0 ? _a : e === null || e === void 0 ? void 0 : e.message);
                }
            });
        }
    }
});
