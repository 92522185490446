"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productsData = void 0;
exports.productsData = [
    {
        product: 'XXX_123_ABC',
        widget_type: 'Slider',
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        clicks: '12345'
    },
    {
        product: 'XXX_456_DEF',
        widget_type: 'Slider',
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        clicks: '12346'
    },
    {
        product: 'XXX_456_DEF',
        widget_type: 'Slider',
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        clicks: '12346'
    },
    {
        product: 'XXX_456_DEF',
        widget_type: 'Slider',
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        clicks: '12346'
    },
    {
        product: 'XXX_456_DEF',
        widget_type: 'Slider',
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        clicks: '12346'
    },
    {
        product: 'XXX_456_DEF',
        widget_type: 'Slider',
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        clicks: '12346'
    },
    {
        product: 'XXX_456_DEF',
        widget_type: 'Slider',
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        clicks: '12346'
    },
    {
        product: 'XXX_456_DEF',
        widget_type: 'Slider',
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        clicks: '12346'
    },
    {
        product: 'XXX_456_DEF',
        widget_type: 'Slider',
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        clicks: '12346'
    },
    {
        product: 'XXX_456_DEF',
        widget_type: 'Slider',
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        clicks: '12346'
    },
    {
        product: 'XXX_456_DEF',
        widget_type: 'Slider',
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        clicks: '12346'
    },
    {
        product: 'XXX_456_DEF',
        widget_type: 'Slider',
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        clicks: '12346'
    },
    {
        product: 'XXX_456_DEF',
        widget_type: 'Slider',
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        clicks: '12346'
    },
    {
        product: 'XXX_456_DEF',
        widget_type: 'Slider',
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        clicks: '12346'
    },
    {
        product: 'XXX_456_DEF',
        widget_type: 'Slider',
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        clicks: '12346'
    },
    {
        product: 'XXX_456_DEF',
        widget_type: 'Slider',
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        clicks: '12346'
    },
    {
        product: 'XXX_456_DEF',
        widget_type: 'Slider',
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        clicks: '12346'
    },
    {
        product: 'XXX_456_DEF',
        widget_type: 'Slider',
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        clicks: '12346'
    },
    {
        product: 'XXX_456_DEF',
        widget_type: 'Slider',
        widget: 'widget 1',
        sponsor: 'sponsor 1',
        clicks: '12346'
    }
];
