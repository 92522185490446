"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScheduleFields = exports.tableOptions = void 0;
const enums_1 = require("../../types/enums");
exports.tableOptions = {
    tableName: 'Schedules',
    action: {
        label: 'Add Schedule',
        route: '/admin/schedules/new',
        routeEditName: 'Schedule Edit'
    },
    operations: {
        save: true,
        edit: true,
        delete: true
    },
    columns: {
        name: {
            type: enums_1.FieldType.string,
            label: 'Name',
            width: 300
        },
        startTime: {
            type: enums_1.FieldType.dateTime,
            label: 'Start date',
            width: 180,
            format: "yyyy-MM-dd HH:mm:ss"
        },
        endTime: {
            type: enums_1.FieldType.dateTime,
            label: 'End date',
            width: 180,
            format: "yyyy-MM-dd HH:mm:ss"
        },
    }
};
exports.ScheduleFields = {
    name: {
        label: 'Name',
        type: enums_1.FieldType.string,
        // required: true,
        isOneInRow: true
    },
    sections: {
        label: 'Sections',
        type: enums_1.FieldType.selectDynamic,
        multiple: true,
        selectOptionsVariable: {
            sourceVariable: 'sections',
            key: 'id',
            value: 'name'
        }
    },
    negateSections: {
        label: 'Exclude Sections',
        type: enums_1.FieldType.boolean,
        classes: 'align-self-end col-lg-6'
    },
    partnerSections: {
        label: 'Partner sections',
        type: enums_1.FieldType.selectDynamic,
        multiple: true,
        selectOptionsVariable: {
            sourceVariable: 'partners',
            key: 'id',
            value: 'name'
        }
    },
    negatePartnerSections: {
        label: 'Exclude Partner Sections',
        type: enums_1.FieldType.boolean,
        classes: 'align-self-end col-lg-6'
    },
    medias: {
        label: 'Medias',
        type: enums_1.FieldType.selectDynamic,
        multiple: true,
        selectOptionsVariable: {
            sourceVariable: 'medias',
            key: 'id',
            value: 'name'
        }
    },
    negateMedias: {
        label: 'Exclude Medias',
        type: enums_1.FieldType.boolean,
        classes: 'align-self-end col-lg-6'
    },
    widgets: {
        label: 'Widgets',
        type: enums_1.FieldType.selectDynamic,
        multiple: true,
        selectOptionsVariable: {
            sourceVariable: 'widgets',
            key: 'id',
            value: 'name'
        }
    },
    negateWidgets: {
        label: 'Exclude Widgets',
        type: enums_1.FieldType.boolean,
        classes: 'align-self-end col-lg-6'
    },
    paths: {
        label: 'Path parts',
        type: enums_1.FieldType.select,
        multiple: true,
        optionsToSelect: {},
        allowCreateSelectItem: true,
    },
    negatePaths: {
        label: 'Exclude Paths',
        type: enums_1.FieldType.boolean,
        classes: 'align-self-end col-lg-6'
    },
    platforms: {
        label: 'Devices',
        type: enums_1.FieldType.select,
        multiple: true,
        optionsToSelect: {
            desktop: 'Desktop',
            mobile: 'Mobile'
        }
    },
    negatePlatforms: {
        label: 'Exclude Devices',
        type: enums_1.FieldType.boolean,
        classes: 'align-self-end col-lg-6'
    },
    startTime: {
        label: 'Start time',
        type: enums_1.FieldType.dateTime,
        dateFormat: 'YYYY-MM-DD HH:mm:ss'
    },
    endTime: {
        label: 'End time',
        type: enums_1.FieldType.dateTime,
        dateFormat: 'YYYY-MM-DD HH:mm:ss'
    }
};
