"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Chart = exports.AnalyticsCards_2 = exports.AnalyticsCards_1 = exports.MiniStatistics = void 0;
exports.MiniStatistics = [
    {
        title: 'Viewable Impressions',
        value: 350897,
        // value: '$350,897',
        description: "<span class='text-sm font-weight-bolder text-success' >+3.48%</span>   Since last month",
        icon: {
            component: 'ni ni-active-40',
            background: 'bg-gradient-danger',
            shape: 'rounded-circle'
        }
    },
    {
        title: 'Clicks',
        value: 2356,
        description: "<span class='text-sm font-weight-bolder text-success' >+12.18%</span>   Since last month",
        icon: {
            component: 'ni ni-chart-pie-35',
            background: 'bg-gradient-warning',
            shape: 'rounded-circle'
        }
    },
    {
        title: 'Revenue Sales',
        value: 924,
        description: "<span class='text-sm font-weight-bolder text-danger' >-5.72%</span>   Since last month",
        icon: {
            component: 'ni ni-money-coins',
            background: 'bg-gradient-success',
            shape: 'rounded-circle'
        }
    },
    {
        title: 'CTR',
        value: 49.65,
        suffix: '%',
        description: "<span class='text-sm font-weight-bolder text-success' >+54.8%</span> Since last month",
        icon: {
            component: 'ni ni-chart-bar-32',
            background: 'bg-gradient-info',
            shape: 'rounded-circle'
        }
    }
];
exports.AnalyticsCards_1 = [
    {
        title: 'My active widgets',
        headers: ['Sponsor Name', 'Widget Name', 'Viewable imps', 'Clicks', 'CTR', 'Start date', 'End date'],
        rows: [
            { name: 'Sponsor 1', widget: 'widget 1', imps: '10', clicks: '100', ctr: '20', startDate: '24.06.2022', endDate: '01.07.2022' },
            { name: 'Sponsor 2', widget: 'widget 2', imps: '20', clicks: '200', ctr: '20', startDate: '25.06.2022', endDate: '01.07.2022' },
            { name: 'Sponsor 3', widget: 'widget 3', imps: '30', clicks: '200', ctr: '20', startDate: '26.06.2022', endDate: '02.07.2022' },
            { name: 'Sponsor 4', widget: 'widget 4', imps: '40', clicks: '400', ctr: '20', startDate: '27.06.2022', endDate: '03.07.2022' }
        ]
    },
    {
        title: 'My draft widgets',
        headers: ['Sponsor name', 'Widget Name', 'Last update'],
        rows: [
            { name: 'sponsor 1', widget: 'widget 1', 'last_update': '24.06.2022' },
            { name: 'sponsor 2', widget: 'widget 2', 'last_update': '25.06.2022 ' },
            { name: 'sponsor 3', widget: 'widget 3', 'last_update': '26.06.2022 ' },
            { name: 'sponsor 4', widget: 'widget 4', 'last_update': '27.06.2022 ' }
        ]
    },
    {
        title: 'My inactive widgets',
        headers: ['Sponsor Name', 'Widget Name', 'Viewable imps', 'Clicks', 'CTR', 'Start date', 'End date'],
        rows: [
            { name: 'Sponsor 1', widget: 'widget 1', imps: '10', clicks: '100', ctr: '20', startDate: '24.06.2022', endDate: '01.07.2022' },
            { name: 'Sponsor 2', widget: 'widget 2', imps: '20', clicks: '200', ctr: '20', startDate: '25.06.2022', endDate: '01.07.2022' },
            { name: 'Sponsor 3', widget: 'widget 3', imps: '30', clicks: '200', ctr: '20', startDate: '26.06.2022', endDate: '02.07.2022' },
            { name: 'Sponsor 4', widget: 'widget 4', imps: '40', clicks: '400', ctr: '20', startDate: '27.06.2022', endDate: '03.07.2022' }
        ]
    }
];
exports.AnalyticsCards_2 = [
    {
        title: 'Most viewed widget (last 7 days)',
        headers: ['Sponsor Name', 'Widget Name', 'Viewable imps', 'Clicks', 'CTR', 'Start date', 'End date'],
        rows: [
            { name: 'Sponsor 1', widget: 'widget 1', imps: '10', clicks: '100', ctr: '20', startDate: '24.06.2022', endDate: '01.07.2022' },
            { name: 'Sponsor 2', widget: 'widget 2', imps: '20', clicks: '200', ctr: '20', startDate: '25.06.2022', endDate: '01.07.2022' },
            { name: 'Sponsor 3', widget: 'widget 3', imps: '30', clicks: '200', ctr: '20', startDate: '26.06.2022', endDate: '02.07.2022' },
            { name: 'Sponsor 4', widget: 'widget 4', imps: '40', clicks: '400', ctr: '20', startDate: '27.06.2022', endDate: '03.07.2022' }
        ]
    },
    {
        title: 'Most clicked products(last 7 days)',
        headers: ['Product name', 'Sponsor name', 'Widget Name', 'Widget type', 'Click'],
        rows: [
            { name: 'product 1', sponsor_name: 'Sponsor 1', widget: 'widget 1', type: 'type 1', clicks: '100' },
            { name: 'product 2', sponsor_name: 'Sponsor 2', widget: 'widget 2', type: 'type 2', clicks: '200' },
            { name: 'product 3', sponsor_name: 'Sponsor 3', widget: 'widget 3', type: 'type 3', clicks: '300' },
            { name: 'product 4', sponsor_name: 'Sponsor 4', widget: 'widget 4', type: 'type 4', clicks: '400' }
        ]
    },
    {
        title: 'Best CTR widget (last 7 days)',
        headers: ['Sponsor Name', 'Widget Name', 'Viewable imps', 'Clicks', 'CTR', 'Start date', 'End date'],
        rows: [
            { name: 'Sponsor 1', widget: 'widget 1', imps: '10', clicks: '100', ctr: '20', startDate: '24.06.2022', endDate: '01.07.2022' },
            { name: 'Sponsor 2', widget: 'widget 2', imps: '20', clicks: '200', ctr: '20', startDate: '25.06.2022', endDate: '01.07.2022' },
            { name: 'Sponsor 3', widget: 'widget 3', imps: '30', clicks: '200', ctr: '20', startDate: '26.06.2022', endDate: '02.07.2022' },
            { name: 'Sponsor 4', widget: 'widget 4', imps: '40', clicks: '400', ctr: '20', startDate: '27.06.2022', endDate: '03.07.2022' }
        ]
    },
];
exports.Chart = {
    title: "Widgets used",
    chart: {
        labels: ['Sponsored', 'Unsponsored'],
        datasets: [{ label: 'Referrals', data: [24, 76] }]
        // labels: ['Slider', 'Grids', 'Immersion', 'Banner', 'Frame'],
        // datasets: [{ label: 'Referrals', data: [25, 3, 12, 7, 10] }]
    },
    // actions: {
    //   route: '',
    //   label: 'See all widgets',
    //   color: 'secondary'
    // },
    table: [
        { name: 'Sponsored', value: '24%' },
        { name: 'Unsponsored', value: '76%' },
        // {name: 'Immersion', value: '10%'},
        // {name: 'Banner', value: '7%'},
        // {name: 'Frame', value: '5%'},
    ]
};
