"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialFieldValues = exports.WidgetOptions = exports.AdditionalClassStylesOptions = exports.tableOptions = void 0;
const enums_1 = require("../../types/enums");
exports.tableOptions = {
    tableName: 'Widget list',
    action: {
        label: 'Add Widget',
        route: '/admin/widgets/new',
        routeChartName: 'Widget Page',
        copy: 'Copy preview page link',
        createCopy: 'Duplicate Widget',
        routeEditName: 'Widget Edit',
        rowClickNavigation: 'Widget Page'
    },
    operations: {
        save: true,
        edit: true,
        chart: true,
        copy: true,
        delete: true,
        createCopy: true,
    },
    columns: {
        enabled: {
            type: enums_1.FieldType.boolean,
            label: '',
            width: 50,
        },
        id: {
            type: enums_1.FieldType.string,
            label: 'Id',
            width: 65,
            fixedWidth: 65,
            readMode: true
        },
        widgetIssues: {
            type: enums_1.FieldType.content,
            width: 18,
            fixedWidth: 18,
            sortable: false,
            label: ''
        },
        name: {
            type: enums_1.FieldType.string,
            label: 'Name',
            width: 410
        },
        type: {
            type: enums_1.FieldType.string,
            label: 'Type',
            width: 190,
            readMode: true
        },
        publisher: {
            label: 'Publisher',
            type: enums_1.FieldType.selectDynamic,
            width: 120,
            fixedWidth: 120,
            selectOptionsVariable: {
                sourceVariable: 'publishers',
                key: 'id',
                value: 'title'
            },
            readMode: true
        },
        // week: {
        //   label: 'Week',
        //   type: FieldType.week,
        //   width: 105
        // },
        campaignID: {
            label: 'Campaign',
            type: enums_1.FieldType.selectDynamic,
            width: 320,
            selectOptionsVariable: {
                sourceVariable: 'campaigns',
                key: 'id',
                value: 'name'
            }
        }
    }
};
exports.AdditionalClassStylesOptions = {
    additionalClassStyles: {
        type: enums_1.FieldType.arrayCard,
        label: 'Additional Class Styles',
        subFields: {
            parentClassName: {
                type: enums_1.FieldType.select,
                label: 'Parent Class Name'
            },
            className: {
                type: enums_1.FieldType.select,
                label: 'Class Name'
            },
            offset: {
                type: enums_1.FieldType.object,
                subFields: {
                    x: {
                        label: 'Offset X',
                        uniqueName: 'Offset X',
                        type: enums_1.FieldType.string,
                        description: 'Defines the horizontal position of an element relative to its containing element. You can specify values in px, %, or other units.'
                    },
                    y: {
                        label: 'Offset Y',
                        uniqueName: 'Offset Y',
                        type: enums_1.FieldType.string,
                        description: 'Defines the vertical position of an element relative to its containing element. You can specify values in px, %, or other units.'
                    }
                }
            },
            color: {
                type: enums_1.FieldType.colorPicker,
                label: 'Text Color',
            },
            backgroundColor: {
                type: enums_1.FieldType.colorPicker,
                label: 'Background Color',
            },
            margin: {
                type: enums_1.FieldType.string,
                label: 'Margin',
                description: 'Defines the space around an element, outside its border. You can specify values in px, %, or other units.<br><br>' +
                    '1 value: Applies equal margin on all sides.<br>' +
                    'Example: "10px" - Adds a 10px margin on all sides<br><br>' +
                    '2 values: Sets top/bottom and left/right margins separately.<br>' +
                    'Example: "10px 20px" - 10px top/bottom margin, 20px left/right margin<br><br>' +
                    '3 values: Sets top, left/right, and bottom margins separately.<br>' +
                    'Example: "10px 20px 15px" - 10px top margin, 20px left/right margin, 15px bottom margin<br><br>' +
                    '4 values: Sets top, right, bottom, and left margins individually.<br>' +
                    'Example: "10px 20px 15px 5px" - 10px top, 20px right, 15px bottom, 5px left',
            },
            padding: {
                type: enums_1.FieldType.string,
                label: 'Padding',
                description: 'Defines the space between the content of an element and its border. You can specify values in px, %, or other units.<br><br>' +
                    '1 value: Applies equal padding on all sides.<br>' +
                    'Example: "10px" - Adds a 10px padding on all sides<br><br>' +
                    '2 values: Sets top/bottom and left/right paddings separately.<br>' +
                    'Example: "10px 20px" - 10px top/bottom padding, 20px left/right padding<br><br>' +
                    '3 values: Sets top, left/right, and bottom paddings separately.<br>' +
                    'Example: "10px 20px 15px" - 10px top padding, 20px left/right padding, 15px bottom padding<br><br>' +
                    '4 values: Sets top, right, bottom, and left paddings individually.<br>' +
                    'Example: "10px 20px 15px 5px" - 10px top, 20px right, 15px bottom, 5px left',
            },
            zIndex: {
                type: enums_1.FieldType.number,
                label: 'Z-Index',
                description: 'Determines the stacking order of positioned elements along the z-axis.<br>' +
                    'Higher z-index values place elements closer to the front, allowing them to overlap elements with lower values or the default stacking order.'
            }
        }
    }
};
exports.WidgetOptions = {
    title: {
        label: 'Title',
        type: enums_1.FieldType.string
    },
    subtitle: {
        label: 'Subtitle',
        type: enums_1.FieldType.string
    },
    subtitle2: {
        label: 'Subtitle 2',
        type: enums_1.FieldType.string
    },
    subtitle3: {
        label: 'Subtitle 3',
        type: enums_1.FieldType.string
    },
    description: {
        label: 'Description',
        type: enums_1.FieldType.string
    },
    everyLink: {
        label: 'Miss-hit link (clear to landing on 1st product)',
        type: enums_1.FieldType.string,
        url: true,
    },
    bannerUrl: {
        label: 'Url',
        type: enums_1.FieldType.string,
        url: true,
    },
    customBgColor: {
        label: 'Custom BG Color #',
        type: enums_1.FieldType.colorPicker
    },
    customCategoriesBgColor: {
        label: 'Custom background color for categories',
        type: enums_1.FieldType.colorPicker
    },
    customFontColor: {
        label: 'Custom Font Color #',
        type: enums_1.FieldType.colorPicker
    },
    customProductTextColor: {
        label: 'Custom Product Text Color #',
        type: enums_1.FieldType.colorPicker
    },
    customCTABGColor: {
        label: 'Custom CTA Background Color #',
        type: enums_1.FieldType.colorPicker
    },
    customCTABGColor2: {
        label: 'Custom CTA Background Color for animation #',
        type: enums_1.FieldType.colorPicker
    },
    customCTAFontColor: {
        label: 'Custom CTA Font Color #',
        type: enums_1.FieldType.colorPicker
    },
    customCTAFontColor2: {
        label: 'Custom CTA Font Color for animation #',
        type: enums_1.FieldType.colorPicker
    },
    reduceAutoFontSize: {
        label: 'Reduce font size on X%',
        type: enums_1.FieldType.number
    },
    reduceCTAFont: {
        label: 'Reduce CTA font size on X%',
        type: enums_1.FieldType.number
    },
    logoSize: {
        label: 'Logo size in %',
        type: enums_1.FieldType.number
    },
    logoSizeMobile: {
        label: 'Mobile logo size in %',
        type: enums_1.FieldType.number
    },
    productImageSize: {
        label: 'Product image size in %',
        type: enums_1.FieldType.number
    },
    productImageSizeMobile: {
        label: 'Mobile product image size in %',
        type: enums_1.FieldType.number
    },
    carouselHeight: {
        label: 'Change Carousel Height (default is 100%)',
        type: enums_1.FieldType.number
    },
    ctaHeight: {
        label: 'CTA height in %',
        type: enums_1.FieldType.number
    },
    descriptionLength: {
        label: 'Description length',
        type: enums_1.FieldType.number
    },
    backgroundPosition: {
        label: 'Background Position',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            center: 'Center',
            top: 'Top',
            bottom: 'Bottom',
            left: 'Left',
            right: 'Right',
            'right-top': 'Right Top',
            'right-bottom': 'Right Bottom',
            'left-top': 'Left Top',
            'left-bottom': 'Left Bottom'
        }
    },
    titlePosition: {
        label: 'Title Position',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            center: 'Center',
            top: 'Top',
            bottom: 'Bottom'
        }
    },
    mobileTitlePosition: {
        label: 'Mobile Title Position',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            center: 'Center',
            top: 'Top',
            bottom: 'Bottom'
        }
    },
    titleAlign: {
        label: 'Title Align',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            center: 'Center',
            left: 'Left',
            right: 'Right'
        }
    },
    mobileTitleAlign: {
        label: 'Mobile Title Align',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            center: 'Center',
            left: 'Left',
            right: 'Right'
        }
    },
    logoPosition: {
        label: 'Logo Position',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            center: 'Center',
            left: 'Left',
            right: 'Right'
        }
    },
    logoPositionMobile: {
        label: 'Mobile Logo Position',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            desktop: 'Like a desktop',
            center: 'Center',
            left: 'Left',
            right: 'Right'
        }
    },
    logoPositionExtra: {
        label: 'Logo Position',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            center: 'Center',
            top: 'Top',
            bottom: 'Bottom',
            left: 'Left',
            right: 'Right',
            'right-top': 'Right Top',
            'right-bottom': 'Right Bottom',
            'left-top': 'Left Top',
            'left-bottom': 'Left Bottom'
        }
    },
    logoPositionMobileExtra: {
        label: 'Mobile Logo Position',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            desktop: 'Like a desktop',
            center: 'Center',
            top: 'Top',
            bottom: 'Bottom',
            left: 'Left',
            right: 'Right',
            'right-top': 'Right Top',
            'right-bottom': 'Right Bottom',
            'left-top': 'Left Top',
            'left-bottom': 'Left Bottom'
        }
    },
    visibleProductsQuantity: {
        label: 'Visible Products Quantity',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            4: '4',
            2: '2'
        }
    },
    fontColor: {
        label: 'Font Color',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            white: 'White',
            black: 'Black'
        }
    },
    bgColor: {
        label: 'Background Color',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            beige: 'Beige',
            white: 'White',
            grey: 'Grey'
        }
    },
    borderColor: {
        label: 'Border Color',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            black: 'Black',
            blue: 'Blue'
        }
    },
    font: {
        label: 'Font',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            default: 'Default',
            oranienbaum: 'Oranienbaum',
            poppins: 'Poppins',
            gotham: 'Gotham',
            pantexttt: 'PanTextTT',
            'kings-caslon-display': 'KingsCaslonDisplay',
            manrope: 'Manrope',
            lato: 'Lato',
            arial: 'Arial',
            'optimal-el': 'Optimal EL',
            'proxima-nova': 'Proxima Nova',
            montserrat: 'Montserrat',
            seikobernino: 'SeikoBernino',
            'seikobernino-light': 'SeikoBernino-Light',
            'circularstd-medium': 'CircularStd-Medium',
            'georgia': 'Georgia',
            'nouvelr': 'NouvelR',
            'fedexsans': 'FedExSans',
            'karla': 'Karla',
            'brandon-grotesque': 'Brandon Grotesque',
        }
    },
    sliderAlign: {
        label: 'Slider Align',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            left: 'Left',
            center: 'Center',
            right: 'Right'
        }
    },
    extraImagePositionOnMobile: {
        label: 'Extra image position on mobile',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            cover: 'Cover',
            contain: 'Contain'
        }
    },
    bgTransparency: {
        label: 'Background Transparency',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            no: 'No',
            blur: 'Blur',
            0: '0%',
            50: '50%',
            75: '75%'
        }
    },
    revision: {
        label: 'Widget Revision',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            default: 'Default',
            'altice-animated-1': 'Altice animated 1',
            'altice-animated-2': 'Altice animated 2',
            'marina-rinaldi': 'Marina Rinaldi',
            'estee-lauder': 'Estee Lauder',
            'integrated-standard-stroili': 'Integrated Standard Stroili',
            'fidenza-village': 'Fidenza Village',
            'giordano-vini': 'Giordano Vini',
            benetton: 'benetton',
            seiko: 'Seiko',
            fiat500: 'Fiat500',
            tods: 'Tods',
            renault: 'Renault',
            fedex: 'FedEx',
            lebebe: 'leBebé'
        }
    },
    productImgMode: {
        label: 'Product Image Mode',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            contain: 'Contain',
            cover: 'Cover'
        }
    },
    buttonAnimation: {
        label: 'Button animation',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            none: 'none',
            'title-from-top-to-bottom': 'Title from top to bottom'
        }
    },
    tagAnimation: {
        label: 'Tag animation',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            none: 'none',
            'pulse-and-rotation': 'Pulse and rotation'
        }
    },
    productNamesFrom: {
        label: 'Take product names from',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            'product-brand': 'product-brand',
            'product-name': 'product-name'
        }
    },
    videoFillMode: {
        label: 'Video fill mode',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            height: 'height',
            width: 'width',
            fill: 'fill'
        }
    },
    bgAnimation: {
        label: 'Background animation',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            none: 'none',
            zoom: 'zoom',
            'ken-burns-right': 'ken-burns-right',
            'ken-burns-up': 'ken-burns-up',
        }
    },
    guide1800x1000: {
        label: 'Guide for 1800x1000',
        type: enums_1.FieldType.select,
        optionsToSelect: {
            'le-parisien-1': 'Le Parisien 1',
            'le-parisien-2': 'Le Parisien 2',
        }
    },
    buttons: {
        label: 'Buttons',
        type: enums_1.FieldType.array,
        subFields: [
            {
                title: {
                    label: 'Button Label 1',
                    uniqueName: "Button Label 1",
                    type: enums_1.FieldType.string
                },
                url: {
                    label: 'Button Url 1',
                    uniqueName: "Button Url 1",
                    type: enums_1.FieldType.string,
                    url: true,
                }
            },
            {
                title: {
                    label: 'Button Label 2',
                    uniqueName: "Button Label 2",
                    type: enums_1.FieldType.string
                },
                url: {
                    label: 'Button Url 2',
                    uniqueName: "Button Url 2",
                    type: enums_1.FieldType.string,
                    url: true,
                }
            },
            {
                title: {
                    label: 'Button Label 3',
                    uniqueName: "Button Label 3",
                    type: enums_1.FieldType.string
                },
                url: {
                    label: 'Button Url 3',
                    uniqueName: "Button Url 3",
                    type: enums_1.FieldType.string,
                    url: true,
                }
            }
        ]
    },
    tagCoordinates: {
        label: 'Tag Coordinates',
        type: enums_1.FieldType.array,
        subFields: [
            {
                x: {
                    label: '1st Tag X%',
                    uniqueName: "1st Tag X%",
                    type: enums_1.FieldType.number
                },
                y: {
                    label: '1st Tag Y%',
                    uniqueName: "1 Tag Y%",
                    type: enums_1.FieldType.number
                }
            },
            {
                x: {
                    label: '2nd Tag X%',
                    uniqueName: "2 Tag X%",
                    type: enums_1.FieldType.number
                },
                y: {
                    label: '2nd Tag Y%',
                    uniqueName: "2 Tag Y%",
                    type: enums_1.FieldType.number
                }
            },
            {
                x: {
                    label: '3rd Tag X%',
                    uniqueName: "3 Tag X%",
                    type: enums_1.FieldType.number
                },
                y: {
                    label: '3rd Tag Y%',
                    uniqueName: "3 Tag Y%",
                    type: enums_1.FieldType.number
                }
            },
            {
                x: {
                    label: '4th Tag X%',
                    uniqueName: "4 Tag X%",
                    type: enums_1.FieldType.number
                },
                y: {
                    label: '4th Tag Y%',
                    uniqueName: "4 Tag Y%",
                    type: enums_1.FieldType.number
                }
            },
            {
                x: {
                    label: '5th Tag X%',
                    uniqueName: "5 Tag X%",
                    type: enums_1.FieldType.number
                },
                y: {
                    label: '5th Tag Y%',
                    uniqueName: "5 Tag Y%",
                    type: enums_1.FieldType.number
                }
            },
            {
                x: {
                    label: '6th Tag X%',
                    uniqueName: "6 Tag X%",
                    type: enums_1.FieldType.number
                },
                y: {
                    label: '6th Tag Y%',
                    uniqueName: "6 Tag Y%",
                    type: enums_1.FieldType.number
                }
            }
        ]
    },
    tagCoordinatesMobile: {
        label: 'Mobile Tag Coordinates',
        type: enums_1.FieldType.array,
        subFields: [
            {
                x: {
                    label: '1st Mobile Tag X%',
                    uniqueName: "1 Mobile Tag X%",
                    type: enums_1.FieldType.number
                },
                y: {
                    label: '1st Mobile Tag Y%',
                    uniqueName: "1 Mobile Tag Y%",
                    type: enums_1.FieldType.number
                }
            },
            {
                x: {
                    label: '2nd Mobile Tag X%',
                    uniqueName: "2 Mobile Tag X%",
                    type: enums_1.FieldType.number
                },
                y: {
                    label: '2nd Mobile Tag Y%',
                    uniqueName: "2 Mobile Tag Y%",
                    type: enums_1.FieldType.number
                }
            },
            {
                x: {
                    label: '3rd Mobile Tag X%',
                    uniqueName: "3 Mobile Tag X%",
                    type: enums_1.FieldType.number
                },
                y: {
                    label: '3rd Mobile Tag Y%',
                    uniqueName: "3 Mobile Tag Y%",
                    type: enums_1.FieldType.number
                }
            },
            {
                x: {
                    label: '4th Mobile Tag X%',
                    uniqueName: "4 Mobile Tag X%",
                    type: enums_1.FieldType.number
                },
                y: {
                    label: '4th Mobile Tag Y%',
                    uniqueName: "4 Mobile Tag Y%",
                    type: enums_1.FieldType.number
                }
            },
            {
                x: {
                    label: '5th Mobile Tag X%',
                    uniqueName: "5 Mobile Tag X%",
                    type: enums_1.FieldType.number
                },
                y: {
                    label: '5th Mobile Tag Y%',
                    uniqueName: "5 Mobile Tag Y%",
                    type: enums_1.FieldType.number
                }
            },
            {
                x: {
                    label: '6th Mobile Tag X%',
                    uniqueName: "6 Mobile Tag X%",
                    type: enums_1.FieldType.number
                },
                y: {
                    label: '6th Mobile Tag Y%',
                    uniqueName: "6 Mobile Tag Y%",
                    type: enums_1.FieldType.number
                }
            }
        ]
    },
    tagLinks: {
        label: 'Tag Directly Links',
        type: enums_1.FieldType.array,
        subFields: [
            {
                url: {
                    label: '1th Tag Link',
                    uniqueName: "1 Tag Link",
                    type: enums_1.FieldType.string,
                    url: true,
                }
            },
            {
                url: {
                    label: '2th Tag Link',
                    uniqueName: "2 Tag Link",
                    type: enums_1.FieldType.string,
                    url: true,
                }
            },
            {
                url: {
                    label: '3th Tag Link',
                    uniqueName: "3 Tag Link",
                    type: enums_1.FieldType.string,
                    url: true,
                }
            },
            {
                url: {
                    label: '4th Tag Link',
                    uniqueName: "4 Tag Link",
                    type: enums_1.FieldType.string,
                    url: true,
                }
            },
            {
                url: {
                    label: '5th Tag Link',
                    uniqueName: "5 Tag Link",
                    type: enums_1.FieldType.string,
                    url: true,
                }
            },
            {
                url: {
                    label: '6th Tag Link',
                    uniqueName: "6 Tag Link",
                    type: enums_1.FieldType.string,
                    url: true,
                }
            }
        ]
    },
    colorList: {
        label: 'Color List',
        type: enums_1.FieldType.array,
        subFields: [
            {
                color: {
                    label: '1th Color',
                    uniqueName: "1 Color",
                    type: enums_1.FieldType.string
                }
            },
            {
                color: {
                    label: '2th Color',
                    uniqueName: "2 Color",
                    type: enums_1.FieldType.string
                }
            },
            {
                color: {
                    label: '3th Color',
                    uniqueName: "3 Color",
                    type: enums_1.FieldType.string
                }
            },
            {
                color: {
                    label: '4th Color',
                    uniqueName: "4 Color",
                    type: enums_1.FieldType.string
                }
            },
            {
                color: {
                    label: '5th Color',
                    uniqueName: "5 Color",
                    type: enums_1.FieldType.string
                }
            },
            {
                color: {
                    label: '6th Color',
                    uniqueName: "6 Color",
                    type: enums_1.FieldType.string
                }
            }
        ]
    },
    colorDots: {
        label: 'Color Dots',
        type: enums_1.FieldType.array,
        subFields: [
            {
                color: {
                    label: 'Dot color 1 #',
                    uniqueName: "Dot color 1",
                    type: enums_1.FieldType.string
                },
                name: {
                    label: 'Color name 1 #',
                    uniqueName: "Color name 1",
                    type: enums_1.FieldType.string
                }
            },
            {
                color: {
                    label: 'Dot color 2 #',
                    uniqueName: "Dot color 2",
                    type: enums_1.FieldType.string
                },
                name: {
                    label: 'Color name 2',
                    uniqueName: "Color name 2",
                    type: enums_1.FieldType.string
                }
            },
            {
                color: {
                    label: 'Dot color 3 #',
                    uniqueName: "Dot color 3",
                    type: enums_1.FieldType.string
                },
                name: {
                    label: 'Color name 3',
                    uniqueName: "Color name 3",
                    type: enums_1.FieldType.string
                }
            },
            {
                color: {
                    label: 'Dot color 4 #',
                    uniqueName: "Dot color 4",
                    type: enums_1.FieldType.string
                },
                name: {
                    label: 'Color name 4',
                    uniqueName: "Color name 4",
                    type: enums_1.FieldType.string
                }
            },
            {
                color: {
                    label: 'Dot color 5 #',
                    uniqueName: "Dot color 5",
                    type: enums_1.FieldType.string
                },
                name: {
                    label: 'Color name 5',
                    uniqueName: "Color name 5",
                    type: enums_1.FieldType.string
                }
            },
            {
                color: {
                    label: 'Dot color 6 #',
                    uniqueName: "Dot color 6",
                    type: enums_1.FieldType.string
                },
                name: {
                    label: 'Color name 6',
                    uniqueName: "Color name 6",
                    type: enums_1.FieldType.string
                }
            },
            {
                color: {
                    label: 'Dot color 7 #',
                    uniqueName: "Dot color 7",
                    type: enums_1.FieldType.string
                },
                name: {
                    label: 'Color name 7',
                    uniqueName: "Color name 7",
                    type: enums_1.FieldType.string
                }
            },
            {
                color: {
                    label: 'Dot color 8 #',
                    uniqueName: "Dot color 8",
                    type: enums_1.FieldType.string
                },
                name: {
                    label: 'Color name 8',
                    uniqueName: "Color name 8",
                    type: enums_1.FieldType.string
                }
            }
        ]
    },
    button: {
        label: 'Button',
        type: enums_1.FieldType.object,
        subFields: {
            title: {
                label: 'Button Label',
                uniqueName: 'Button Label',
                type: enums_1.FieldType.string
            },
            url: {
                label: 'Button Url',
                uniqueName: 'Button Url',
                type: enums_1.FieldType.string,
                url: true,
            }
        }
    },
    productButton: {
        label: 'Product button',
        type: enums_1.FieldType.object,
        subFields: {
            title: {
                label: 'Product button Label',
                uniqueName: 'Product button Label',
                type: enums_1.FieldType.string
            },
            url: {
                label: 'Product button Url',
                uniqueName: 'Product button Url',
                type: enums_1.FieldType.string,
                url: true,
            }
        }
    },
    photos: {
        label: 'Photos',
        type: enums_1.FieldType.array,
        subFields: [
            {
                image: {
                    label: 'Image',
                    uniqueName: "Image 1",
                    type: enums_1.FieldType.image
                },
                link: {
                    label: 'Link',
                    uniqueName: "Link 1",
                    type: enums_1.FieldType.string,
                    url: true,
                }
            },
            {
                image: {
                    label: 'Image',
                    uniqueName: "Image 2",
                    type: enums_1.FieldType.image
                },
                link: {
                    label: 'Link',
                    uniqueName: "Link 2",
                    type: enums_1.FieldType.string,
                    url: true,
                }
            },
            {
                image: {
                    label: 'Image',
                    uniqueName: "Image 3",
                    type: enums_1.FieldType.image
                },
                link: {
                    label: 'Link',
                    uniqueName: "Link 3",
                    type: enums_1.FieldType.string,
                    url: true,
                }
            },
            {
                image: {
                    label: 'Image',
                    uniqueName: "Image 4",
                    type: enums_1.FieldType.image
                },
                link: {
                    label: 'Link',
                    uniqueName: "Link 4",
                    type: enums_1.FieldType.string,
                    url: true,
                }
            },
            {
                image: {
                    label: 'Image',
                    uniqueName: "Image 5",
                    type: enums_1.FieldType.image
                },
                link: {
                    label: 'Link',
                    uniqueName: "Link 5",
                    type: enums_1.FieldType.string,
                    url: true,
                }
            },
            {
                image: {
                    label: 'Image',
                    uniqueName: "Image 6",
                    type: enums_1.FieldType.image
                },
                link: {
                    label: 'Link',
                    uniqueName: "Link 6",
                    type: enums_1.FieldType.string,
                    url: true,
                }
            },
            {
                image: {
                    label: 'Image',
                    uniqueName: "Image 7",
                    type: enums_1.FieldType.image
                },
                link: {
                    label: 'Link',
                    uniqueName: "Link 7",
                    type: enums_1.FieldType.string,
                    url: true,
                }
            },
            {
                image: {
                    label: 'Image',
                    uniqueName: "Image 8",
                    type: enums_1.FieldType.image
                },
                link: {
                    label: 'Link',
                    uniqueName: "Link 8",
                    type: enums_1.FieldType.string,
                    url: true,
                }
            }
        ]
    },
    automaticallyScrollProducts: {
        label: 'Automatically Scroll Products',
        type: enums_1.FieldType.boolean
    },
    everyLinkToProduct: {
        label: 'Miss-hit link to product',
        type: enums_1.FieldType.boolean
    },
    hidePrices: {
        label: 'Show Prices',
        type: enums_1.FieldType.boolean,
        revert: true
    },
    hideNames: {
        label: 'Show Names',
        type: enums_1.FieldType.boolean,
        revert: true
    },
    showPlayButton: {
        label: 'Show Play Button',
        type: enums_1.FieldType.boolean
    },
    removeBlur: {
        label: 'Show Blur Around Background',
        type: enums_1.FieldType.boolean,
        revert: true
    },
    removeShadow: {
        label: 'Show Shadow Into Background',
        type: enums_1.FieldType.boolean,
        revert: true
    },
    automaticallyOpenProducts: {
        label: 'Automatically Open',
        type: enums_1.FieldType.boolean,
        defaultOn: true
    },
    alreadyOpenProducts: {
        label: 'Start opened',
        type: enums_1.FieldType.boolean
    },
    blackTitle: {
        label: 'Black Title',
        type: enums_1.FieldType.boolean
    },
    bigNumbers: {
        label: 'Big Numbers On Products',
        type: enums_1.FieldType.boolean
    },
    productBgWhite: {
        label: 'Products Background White',
        type: enums_1.FieldType.boolean
    },
    productBgGrey: {
        label: 'Products Background Grey',
        type: enums_1.FieldType.boolean
    },
    cropProductsBackground: {
        label: 'Crop Products Background',
        type: enums_1.FieldType.boolean
    },
    trimProductsBackground: {
        label: 'Trim Products Background',
        type: enums_1.FieldType.boolean
    },
    showArrows: {
        label: 'Show Arrows',
        type: enums_1.FieldType.boolean,
        defaultOn: true
    },
    darkMode: {
        label: 'Dark Mode',
        type: enums_1.FieldType.boolean
    },
    blurMode: {
        label: 'Blur Mode',
        type: enums_1.FieldType.boolean
    },
    justifyTitleBlockCenter: {
        label: 'Justify Title Block Center',
        type: enums_1.FieldType.boolean
    },
    titleIsCapital: {
        label: 'Title is capital',
        type: enums_1.FieldType.boolean
    },
    subtitleIsCapital: {
        label: 'Subtitle is capital',
        type: enums_1.FieldType.boolean
    },
    productImageAlphaMatting: {
        label: 'Product Image Alpha Matting',
        type: enums_1.FieldType.boolean
    },
    showAdv: {
        label: 'Show Adv tag',
        type: enums_1.FieldType.boolean,
        defaultOn: true
    },
    arrowInCircle: {
        label: 'Arrow in circle',
        type: enums_1.FieldType.boolean
    },
    whiteArrows: {
        label: 'Arrows are white',
        type: enums_1.FieldType.boolean
    },
    poweredByCools: {
        label: 'Powered by COOLS',
        type: enums_1.FieldType.boolean
    },
    fullScreenBG: {
        label: 'Set fullscreen Background',
        type: enums_1.FieldType.boolean
    },
    soundOnBtn: {
        label: 'Add sound button',
        type: enums_1.FieldType.boolean
    },
    transparentTags: {
        label: 'Transparent tags',
        type: enums_1.FieldType.boolean
    },
    showDescription: {
        label: 'Show Description',
        type: enums_1.FieldType.boolean,
        defaultOn: true
    },
    showDiscountTag: {
        label: 'Show Discount Tag',
        type: enums_1.FieldType.boolean,
    },
    logoFillMode: {
        label: 'Logo fill mode',
        type: enums_1.FieldType.boolean,
    },
    blankSpaceBehindLogo: {
        label: 'Blank space behind the logo',
        type: enums_1.FieldType.boolean,
    },
    carouselBgWhite: {
        label: 'White carousel background',
        type: enums_1.FieldType.boolean,
    },
    smartCardMode: {
        label: 'Smart card mode',
        type: enums_1.FieldType.boolean,
    },
    dotTag: {
        label: 'Dot inside tag',
        type: enums_1.FieldType.boolean,
    },
    namePriceAnimation: {
        label: 'Name-Price animation',
        type: enums_1.FieldType.boolean,
    },
    showProductImage: {
        label: 'Show product image',
        type: enums_1.FieldType.boolean,
    },
    productImageErodeSize: {
        label: 'Product Image Erode Size',
        type: enums_1.FieldType.number
    },
    productImageBackgroundThreshold: {
        label: 'Product Image Background Threshold',
        type: enums_1.FieldType.number
    },
    productImageForegroundThreshold: {
        label: 'Product Image Foreground Threshold',
        type: enums_1.FieldType.number
    },
    customCtaButtonShape: {
        label: 'Custom button shape (number of px)',
        type: enums_1.FieldType.number
    },
    customColorDotsShape: {
        label: 'Custom color dots shape (number of px)',
        type: enums_1.FieldType.number
    },
    logoImageURL: {
        label: 'Logo',
        type: enums_1.FieldType.image
    },
    extraLogoImageURL: {
        label: 'Extra Logo',
        type: enums_1.FieldType.image
    },
    bgVideoURL: {
        label: 'Background Video',
        type: enums_1.FieldType.video
    },
    video2URL: {
        label: 'Video 2',
        type: enums_1.FieldType.video
    },
    backgroundImageURL: {
        label: 'Background Image',
        type: enums_1.FieldType.image,
        required: true
    },
    backgroundImageURLMobile: {
        label: 'Background Image (Mobile)',
        type: enums_1.FieldType.image
    },
    backgroundImageURLHorizontal: {
        label: 'Background Image Horizontal',
        type: enums_1.FieldType.image,
        required: true
    },
    backgroundImageURLVertical: {
        label: 'Background Image Vertical',
        type: enums_1.FieldType.image,
        required: true
    },
    categoryCards: {
        label: 'Category Cards',
        type: enums_1.FieldType.arrayCard,
        subFields: {
            image1: {
                label: '1st Image',
                type: enums_1.FieldType.image,
            },
            title1: {
                label: '1st Title',
                type: enums_1.FieldType.string,
            },
            image2: {
                label: '2nd Image',
                type: enums_1.FieldType.image,
            },
            title2: {
                label: '2nd Title',
                type: enums_1.FieldType.string,
            },
            description1: {
                label: 'Description',
                type: enums_1.FieldType.text,
            },
            image3: {
                label: '3rd Image 2',
                type: enums_1.FieldType.image,
            },
            button: {
                label: 'Button',
                type: enums_1.FieldType.object,
                subFields: {
                    title: {
                        label: 'Button Label',
                        uniqueName: 'Button Label',
                        type: enums_1.FieldType.string
                    },
                    url: {
                        label: 'Button Url',
                        uniqueName: 'Button Url',
                        type: enums_1.FieldType.string,
                        url: true,
                    }
                }
            }
        }
    },
    categoriesTextColor: {
        type: enums_1.FieldType.colorPicker,
        label: 'Color of the categories text',
    },
    categoriesButtonBgColor: {
        type: enums_1.FieldType.colorPicker,
        label: 'BG Color of the categories button',
    },
    categoriesButtonTextColor: {
        type: enums_1.FieldType.colorPicker,
        label: 'Color of the categories buttons text',
    },
    starsAndNightsColor: {
        type: enums_1.FieldType.colorPicker,
        label: 'Color of the starts and nights (smart-card)'
    },
    bgColorOfTheCard: {
        type: enums_1.FieldType.colorPicker,
        label: 'Bg Color of the card (smart-card)'
    },
    cardHeight: {
        type: enums_1.FieldType.number,
        label: 'Height of the card in % (smart-card)'
    }
};
// TODO: delete after tests
// export const WidgetToOptions = {
//   product: [
//     'title',
//     'subtitle',
//     'automaticallyScrollProducts',
//     'everyLinkToProduct',
//     'everyLink',
//     'logoImageURL',
//     'reduceAutoFontSize',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold'
//   ],
//   product_2p: [
//     'title',
//     'subtitle',
//     'automaticallyScrollProducts',
//     'everyLinkToProduct',
//     'everyLink',
//     'logoImageURL',
//     'reduceAutoFontSize',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold'
//   ],
//   layout_multi: [
//     'title',
//     'subtitle',
//     'automaticallyScrollProducts',
//     'everyLinkToProduct',
//     'everyLink',
//     'reduceAutoFontSize',
//     'logoImageURL',
//     'backgroundImageURL',
//     'backgroundImageURLMobile',
//     'buttons',
//     'hidePrices',
//     'hideNames',
//     'backgroundPosition',
//     'removeBlur',
//     'removeShadow',
//     'titlePosition',
//     'mobileTitlePosition',
//     'titleAlign',
//     'mobileTitleAlign',
//     'fontColor',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold'
//   ],
//   layout: [
//     'title',
//     'subtitle',
//     'automaticallyScrollProducts',
//     'everyLinkToProduct',
//     'everyLink',
//     'reduceAutoFontSize',
//     'logoImageURL',
//     'backgroundImageURL',
//     'backgroundImageURLMobile',
//     'buttons',
//     'hidePrices',
//     'hideNames',
//     'backgroundPosition',
//     'removeBlur',
//     'removeShadow',
//     'titlePosition',
//     'mobileTitlePosition',
//     'titleAlign',
//     'mobileTitleAlign',
//     'logoPosition',
//     'logoPositionMobile',
//     'fontColor',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold'
//   ],
//   immersion: [
//     'title',
//     'subtitle',
//     'automaticallyScrollProducts',
//     'everyLinkToProduct',
//     'everyLink',
//     'reduceAutoFontSize',
//     'logoImageURL',
//     'backgroundImageURL',
//     'backgroundImageURLMobile',
//     'buttons',
//     'hidePrices',
//     'hideNames',
//     'backgroundPosition',
//     'removeBlur',
//     'removeShadow',
//     'titlePosition',
//     'mobileTitlePosition',
//     'titleAlign',
//     'mobileTitleAlign',
//     'logoPosition',
//     'logoPositionMobile',
//     'fontColor',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold',
//     'customProductTextColor'
//   ],
//   dynamic_slider: [
//     'title',
//     'subtitle',
//     'subtitle2',
//     'automaticallyScrollProducts',
//     'everyLinkToProduct',
//     'everyLink',
//     'backgroundImageURL',
//     'backgroundImageURLMobile',
//     'hidePrices',
//     'hideNames',
//     'automaticallyOpenProducts',
//     'alreadyOpenProducts',
//     'backgroundPosition',
//     'visibleProductsQuantity',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold'
//   ],
//   showcase: [
//     'title',
//     'subtitle',
//     'automaticallyScrollProducts',
//     'button',
//     'everyLinkToProduct',
//     'everyLink',
//     'backgroundImageURL',
//     'backgroundImageURLMobile',
//     'hidePrices',
//     'hideNames',
//     'blackTitle',
//     'backgroundPosition',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold'
//   ],
//   banner: [
//     'title',
//     'subtitle',
//     'bannerUrl',
//     'backgroundPosition',
//     'backgroundImageURL',
//     'backgroundImageURLMobile',
//     'reduceAutoFontSize',
//     'font',
//     'logoImageURL',
//     'logoSize',
//     'logoSizeMobile',
//     'logoPositionExtra',
//     'logoPositionMobileExtra',
//     'removeShadow'
//   ],
//   frame: [
//     'title',
//     'subtitle',
//     'automaticallyScrollProducts',
//     'everyLinkToProduct',
//     'everyLink',
//     'logoImageURL',
//     'reduceAutoFontSize',
//     'hidePrices',
//     'hideNames',
//     'productBgGrey',
//     'button',
//     'borderColor',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold'
//   ],
//   trend_list: [
//     'title',
//     'subtitle',
//     'automaticallyScrollProducts',
//     'everyLinkToProduct',
//     'everyLink',
//     'logoImageURL',
//     'reduceAutoFontSize',
//     'bigNumbers',
//     'productBgWhite',
//     'titleAlign',
//     'mobileTitleAlign',
//     'logoSize',
//     'logoSizeMobile',
//     'productBgWhite',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold'
//   ],
//   slider: [
//     'title',
//     'subtitle',
//     'automaticallyScrollProducts',
//     'everyLinkToProduct',
//     'everyLink',
//     'logoImageURL',
//     'reduceAutoFontSize',
//     'hideNames',
//     'productBgGrey',
//     'bgColor',
//     'titleAlign',
//     'mobileTitleAlign',
//     'bgColor',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold',
//     'showArrows',
//     'customBgColor',
//     'font'
//   ],
//   minimal_photos: [
//     'title',
//     'everyLinkToProduct',
//     'everyLink',
//     'reduceAutoFontSize',
//     'photos',
//     'logoImageURL',
//     'titleAlign',
//     'mobileTitleAlign'
//   ],
//   big_numbers_photos: [
//     'title',
//     'everyLinkToProduct',
//     'everyLink',
//     'reduceAutoFontSize',
//     'photos',
//     'titleAlign',
//     'mobileTitleAlign',
//     'logoSize',
//     'logoSizeMobile'
//   ],
//   integrated_standard: [
//     'title',
//     'subtitle',
//     'subtitle2',
//     'automaticallyScrollProducts',
//     'backgroundImageURL',
//     'logoImageURL',
//     'description',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold',
//     'showArrows',
//     'font',
//     'hidePrices',
//     'hideNames',
//     'customBgColor',
//     'everyLinkToProduct',
//     'everyLink',
//     'font',
//     'sliderAlign',
//     'backgroundPosition',
//     'showAdv',
//     'reduceAutoFontSize',
//     'whiteArrows'
//   ],
//   grids: [
//     'title',
//     'subtitle',
//     'subtitle2',
//     'subtitle3',
//     'automaticallyScrollProducts',
//     'everyLinkToProduct',
//     'everyLink',
//     'reduceAutoFontSize',
//     'logoImageURL',
//     'extraLogoImageURL',
//     'backgroundImageURL',
//     'backgroundImageURLMobile',
//     'button',
//     'hidePrices',
//     'hideNames',
//     'backgroundPosition',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold',
//     'showArrows',
//     'photos',
//     'titleAlign',
//     'mobileTitleAlign',
//     'darkMode',
//     'font',
//     'justifyTitleBlockCenter',
//     'reduceCTAFont',
//     'productImageSize',
//     'productImageSizeMobile',
//     'extraImagePositionOnMobile',
//     'revision'
//   ],
//   overlay: [
//     'title',
//     'subtitle',
//     'automaticallyScrollProducts',
//     'everyLinkToProduct',
//     'everyLink',
//     'reduceAutoFontSize',
//     'logoImageURL',
//     'backgroundImageURL',
//     'button',
//     'hidePrices',
//     'hideNames',
//     'backgroundPosition',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold',
//     'showArrows',
//     'titleAlign',
//     'mobileTitleAlign',
//     'darkMode',
//     'blurMode',
//     'justifyTitleBlockCenter',
//     'reduceCTAFont',
//     'revision'
//   ],
//   slider_box: [
//     'title',
//     'subtitle',
//     'automaticallyScrollProducts',
//     'everyLinkToProduct',
//     'everyLink',
//     'reduceAutoFontSize',
//     'logoImageURL',
//     'button',
//     'hidePrices',
//     'hideNames',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold',
//     'showArrows',
//     'titleAlign',
//     'mobileTitleAlign',
//     'darkMode',
//     'justifyTitleBlockCenter',
//     'reduceCTAFont',
//     'font'
//   ],
//   immersive_one_product: [
//     'title',
//     'subtitle',
//     'automaticallyScrollProducts',
//     'everyLinkToProduct',
//     'everyLink',
//     'reduceAutoFontSize',
//     'logoImageURL',
//     'backgroundImageURL',
//     'backgroundImageURLMobile',
//     'button',
//     'backgroundPosition',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold',
//     'showArrows',
//     'titleAlign',
//     'mobileTitleAlign',
//     'darkMode',
//     'font',
//     'reduceCTAFont',
//     'productImageSize',
//     'productImageSizeMobile',
//     'sliderAlign',
//     'colorDots',
//     'photos',
//     'arrowInCircle',
//     'whiteArrows',
//     'bgTransparency',
//     'hideNames'
//   ],
//   banner_box: [
//     'title',
//     'subtitle',
//     'bannerUrl',
//     'backgroundPosition',
//     'backgroundImageURL',
//     'backgroundImageURLMobile',
//     'reduceAutoFontSize',
//     'reduceCTAFont',
//     'font',
//     'logoImageURL',
//     'titleAlign',
//     'titlePosition',
//     'mobileTitleAlign',
//     'sliderAlign',
//     'button',
//     'darkMode',
//     'customBgColor',
//     'customFontColor',
//     'titleIsCapital',
//     'subtitleIsCapital'
//   ],
//   vertical_shop_video: [
//     'title',
//     'subtitle',
//     'automaticallyScrollProducts',
//     'everyLinkToProduct',
//     'everyLink',
//     'reduceAutoFontSize',
//     'logoImageURL',
//     'reduceCTAFont',
//     'hideNames',
//     'hidePrices',
//     'titleAlign',
//     'titlePosition',
//     'showArrows',
//     'titleIsCapital',
//     'subtitleIsCapital',
//     'button',
//     'bgVideoURL',
//     'customFontColor',
//     'logoPositionExtra',
//     'font',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold'
//   ],
//   vertical_shop_image: [
//     'title',
//     'subtitle',
//     'automaticallyScrollProducts',
//     'everyLinkToProduct',
//     'everyLink',
//     'reduceAutoFontSize',
//     'logoImageURL',
//     'reduceCTAFont',
//     'hideNames',
//     'hidePrices',
//     'titleAlign',
//     'titlePosition',
//     'showArrows',
//     'titleIsCapital',
//     'subtitleIsCapital',
//     'button',
//     'backgroundImageURL',
//     'backgroundPosition',
//     'customFontColor',
//     'logoPositionExtra',
//     'font',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold',
//     'revision'
//   ],
//   product_overlay_video: [
//     'automaticallyScrollProducts',
//     'everyLinkToProduct',
//     'everyLink',
//     'reduceAutoFontSize',
//     'logoImageURL',
//     'reduceCTAFont',
//     'showArrows',
//     'button',
//     'bgVideoURL',
//     'logoPositionExtra',
//     'font',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold'
//   ],
//   video: [
//     'everyLink',
//     'reduceAutoFontSize',
//     'logoImageURL',
//     'reduceCTAFont',
//     'showArrows',
//     'button',
//     'bgVideoURL',
//     'logoPositionExtra',
//     'font'
//   ],
//   social: [
//     'automaticallyScrollProducts',
//     'everyLinkToProduct',
//     'everyLink',
//     'reduceAutoFontSize',
//     'logoImageURL',
//     'reduceCTAFont',
//     'showArrows',
//     'button',
//     'logoPositionExtra',
//     'font',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold',
//     'photos'
//   ],
//   column: [
//     'title',
//     'subtitle',
//     'automaticallyScrollProducts',
//     'everyLinkToProduct',
//     'everyLink',
//     'reduceAutoFontSize',
//     'logoImageURL',
//     'reduceCTAFont',
//     'hideNames',
//     'hidePrices',
//     'titleAlign',
//     'titlePosition',
//     'titleIsCapital',
//     'subtitleIsCapital',
//     'button',
//     'customFontColor',
//     'logoPositionExtra',
//     'font',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold'
//   ],
//   product_tag: [
//     'everyLinkToProduct',
//     'everyLink',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold',
//     'reduceCTAFont',
//     'hideNames',
//     'hidePrices',
//     'font',
//     'button',
//     'backgroundImageURL',
//     'backgroundImageURLMobile',
//     'backgroundPosition',
//     'tagCoordinates',
//     'tagCoordinatesMobile',
//     'tagLinks',
//     'logoImageURL',
//     'logoPosition',
//     'logoPositionMobile',
//     'logoSize',
//     'logoSizeMobile'
//   ],
//   immersive_image: [
//     'title',
//     'subtitle',
//     'automaticallyScrollProducts',
//     'everyLinkToProduct',
//     'everyLink',
//     'reduceAutoFontSize',
//     'logoImageURL',
//     'backgroundImageURL',
//     'backgroundImageURLMobile',
//     'button',
//     'backgroundPosition',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold',
//     'showArrows',
//     'titleAlign',
//     'mobileTitleAlign',
//     'darkMode',
//     'font',
//     'reduceCTAFont',
//     'productImageSize',
//     'productImageSizeMobile',
//     'sliderAlign',
//     'arrowInCircle',
//     'whiteArrows',
//     'bgTransparency',
//     'hideNames'
//   ],
//   scrolling_cube: [
//     'title',
//     'subtitle',
//     'automaticallyScrollProducts',
//     'everyLinkToProduct',
//     'everyLink',
//     'reduceAutoFontSize',
//     'logoImageURL',
//     'backgroundImageURL',
//     // 'backgroundImageURLMobile',
//     'button',
//     'backgroundPosition',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold',
//     'showArrows',
//     'titleAlign',
//     'mobileTitleAlign',
//     'darkMode',
//     'font',
//     'reduceCTAFont',
//     'productImageSize',
//     'productImageSizeMobile',
//     'sliderAlign',
//     'arrowInCircle',
//     'whiteArrows',
//     'bgTransparency',
//     'hideNames'
//   ],
//   landscape_shop: [
//     'title',
//     // 'subtitle',
//     'automaticallyScrollProducts',
//     'everyLinkToProduct',
//     'everyLink',
//     'reduceAutoFontSize',
//     'logoImageURL',
//     'logoPosition',
//     'logoSize',
//     'backgroundImageURL',
//     'hidePrices',
//     'hideNames',
//     'backgroundPosition',
//     'cropProductsBackground',
//     'trimProductsBackground',
//     'productImageAlphaMatting',
//     'productImageErodeSize',
//     'productImageBackgroundThreshold',
//     'productImageForegroundThreshold',
//     // 'showArrows',
//     'titleAlign',
//     // 'blackTitle',
//     'customBgColor',
//     'customFontColor'
//   ],
//   sepia: [
//     'title',
//     'subtitle',
//     'everyLink',
//     'reduceAutoFontSize',
//     'logoImageURL',
//     'reduceCTAFont',
//     'titleAlign',
//     'titlePosition',
//     'titleIsCapital',
//     'subtitleIsCapital',
//     'button',
//     'backgroundImageURL',
//     'backgroundPosition',
//     'customFontColor',
//     'logoPositionExtra',
//     'font',
//     'logoSize',
//     'poweredByCools'
//   ]
// }
exports.initialFieldValues = {
    match_context: [
        {
            sections: { negate: false, items: [] },
            partnerSections: { negate: false, items: [] },
            medias: { negate: false, items: [] },
            platforms: { negate: false, items: [] }
            // genders: { negate: false, items: [] }
        }
    ],
    static: true,
    data: {
        buttons: [
            { title: '', url: '' },
            { title: '', url: '' },
            { title: '', url: '' }
        ],
        tagCoordinates: [
            { x: 0, y: 0 },
            { x: 0, y: 0 },
            { x: 0, y: 0 },
            { x: 0, y: 0 },
            { x: 0, y: 0 },
            { x: 0, y: 0 }
        ],
        tagCoordinatesMobile: [
            { x: 0, y: 0 },
            { x: 0, y: 0 },
            { x: 0, y: 0 },
            { x: 0, y: 0 },
            { x: 0, y: 0 },
            { x: 0, y: 0 }
        ],
        tagLinks: [
            { url: '' },
            { url: '' },
            { url: '' },
            { url: '' },
            { url: '' },
            { url: '' }
        ],
        colorList: [
            { color: '' },
            { color: '' },
            { color: '' },
            { color: '' },
            { color: '' },
            { color: '' }
        ],
        products: [],
        button: { title: '', url: '' },
        productButton: { title: '', url: '' },
        automaticallyScrollProducts: true,
        automaticallyOpenProducts: true,
        everyLinkToProduct: true,
        backgroundPosition: 'center',
        logoPosition: 'center',
        logoPositionMobile: 'desktop',
        visibleProductsQuantity: 4,
        titlePosition: 'center',
        mobileTitlePosition: 'center',
        titleAlign: 'center',
        mobileTitleAlign: 'center',
        fontColor: 'white',
        bigNumbers: true,
        bgColor: 'beige',
        borderColor: 'black',
        photos: [
            { image: '', link: '' },
            { image: '', link: '' },
            { image: '', link: '' },
            { image: '', link: '' },
            { image: '', link: '' },
            { image: '', link: '' },
            { image: '', link: '' },
            { image: '', link: '' }
        ],
        showArrows: true,
        colorDots: [
            { color: '' },
            { color: '' },
            { color: '' },
            { color: '' },
            { color: '' },
            { color: '' },
            { color: '' },
            { color: '' }
        ],
        arrowInCircle: true
        // TODO: move to html
    },
    gender: 'men',
    enabled: false,
    isAdaptive: false,
    redirect: true,
    variations: {
        a: {
            enabled: false
        }
    }
};
