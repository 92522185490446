"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.steps = void 0;
exports.steps = [
    'Main configuration',
    'Template selection',
    'Widget configuration',
    'Campaign configuration',
    'Preview'
];
