"use strict";
/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FrontendService = exports.AuthService = exports.PagedResultDto = exports.ListResultDto = exports.basePath = exports.getConfigs = exports.axios = exports.serviceOptions = void 0;
// Add default options
exports.serviceOptions = {};
// Instance selector
function axios(configs, resolve, reject) {
    if (exports.serviceOptions.axios) {
        return exports.serviceOptions.axios
            .request(configs)
            .then(function (res) {
            resolve(res.data);
        })
            .catch(function (err) {
            reject(err);
        });
    }
    else {
        throw new Error('please inject yourself instance like axios  ');
    }
}
exports.axios = axios;
function getConfigs(method, contentType, url, options) {
    var configs = __assign(__assign({}, options), { method: method, url: url });
    configs.headers = __assign(__assign({}, options.headers), { 'Content-Type': contentType });
    return configs;
}
exports.getConfigs = getConfigs;
exports.basePath = '';
var ListResultDto = /** @class */ (function () {
    function ListResultDto() {
    }
    return ListResultDto;
}());
exports.ListResultDto = ListResultDto;
var PagedResultDto = /** @class */ (function () {
    function PagedResultDto() {
    }
    return PagedResultDto;
}());
exports.PagedResultDto = PagedResultDto;
// customer definition
// empty
var AuthService = /** @class */ (function () {
    function AuthService() {
    }
    /**
     * Registering new users
     */
    AuthService.signUp = function (params, options) {
        if (params === void 0) { params = {}; }
        if (options === void 0) { options = {}; }
        return new Promise(function (resolve, reject) {
            var url = exports.basePath + '/auth/signup';
            var configs = getConfigs('post', 'application/json', url, options);
            var data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    };
    /**
     * Logging in
     */
    AuthService.signIn = function (params, options) {
        if (params === void 0) { params = {}; }
        if (options === void 0) { options = {}; }
        return new Promise(function (resolve, reject) {
            var url = exports.basePath + '/auth/signin';
            var configs = getConfigs('post', 'application/json', url, options);
            var data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    };
    /**
     *
     */
    AuthService.info = function (options) {
        if (options === void 0) { options = {}; }
        return new Promise(function (resolve, reject) {
            var url = exports.basePath + '/auth/info';
            var configs = getConfigs('get', 'application/json', url, options);
            /** 适配ios13，get请求不允许带body */
            axios(configs, resolve, reject);
        });
    };
    AuthService.userInfo = function (options) {
        if (options === void 0) { options = {}; }
        return new Promise(function (resolve, reject) {
            var url = exports.basePath + '/auth/user';
            var configs = getConfigs('get', 'application/json', url, options);
            /** 适配ios13，get请求不允许带body */
            axios(configs, resolve, reject);
        });
    };
    return AuthService;
}());
exports.AuthService = AuthService;
var FrontendService = /** @class */ (function () {
    function FrontendService() {
    }
    /**
     * Returns config to be used by the frontend
     */
    FrontendService.config = function (options) {
        if (options === void 0) { options = {}; }
        return new Promise(function (resolve, reject) {
            var url = exports.basePath + '/config.js';
            var configs = getConfigs('get', 'application/json', url, options);
            /** 适配ios13，get请求不允许带body */
            axios(configs, resolve, reject);
        });
    };
    /**
     * Passes all requests down to the frontend, either served or dist.
     */
    FrontendService.request = function (options) {
        if (options === void 0) { options = {}; }
        return new Promise(function (resolve, reject) {
            var url = exports.basePath + '/*';
            var configs = getConfigs('all', 'application/json', url, options);
            var data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    };
    return FrontendService;
}());
exports.FrontendService = FrontendService;
