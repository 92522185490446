"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const vee_validate_1 = require("vee-validate");
const vue_class_component_1 = require("vue-class-component");
class Props {
    constructor() {
        this.name = (0, vue_class_component_1.prop)({ type: String, required: true });
        this.id = (0, vue_class_component_1.prop)({ type: String, required: true });
        this.checked = (0, vue_class_component_1.prop)({ type: Boolean, default: false });
        this.revert = (0, vue_class_component_1.prop)({ type: Boolean, default: false });
        this.labelClass = (0, vue_class_component_1.prop)({ type: String, default: '' });
        this.inputClass = (0, vue_class_component_1.prop)({ type: String, default: '' });
    }
}
let BaseSwitch = class BaseSwitch extends vue_class_component_1.Vue.with(Props) {
    constructor() {
        super(...arguments);
        this.touched = false;
    }
    get model() {
        return this.revert ? !this.checked : this.checked;
    }
    set model(check) {
        if (!this.touched) {
            this.touched = true;
        }
        this.$emit('input', this.revert ? !check : check);
    }
};
BaseSwitch = __decorate([
    (0, vue_class_component_1.Options)({
        components: { Field: vee_validate_1.Field },
        inheritAttrs: false
    })
], BaseSwitch);
exports.default = BaseSwitch;
