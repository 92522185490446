"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterStore = void 0;
const pinia_1 = require("pinia");
exports.filterStore = (0, pinia_1.defineStore)('filter', {
    state: () => ({
        _filterString: ''
    }),
    getters: {
        getFilter(state) {
            return state._filterString;
        }
    },
    actions: {
        setFilter(str) {
            this._filterString = str;
        }
    }
});
