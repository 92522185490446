"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableOptions = void 0;
const enums_1 = require("../../types/enums");
exports.tableOptions = {
    tableName: 'Sections',
    action: {
        label: 'Add Section',
        route: ''
    },
    operations: {
        save: true,
        delete: true
    },
    columns: {
        id: {
            type: enums_1.FieldType.string,
            label: 'Id',
            width: 55,
            readMode: true
        },
        enabled: {
            type: enums_1.FieldType.boolean,
            label: 'Enabled',
            width: 100
        },
        name: {
            type: enums_1.FieldType.string,
            label: 'Name',
            width: 200
        },
        title: {
            type: enums_1.FieldType.string,
            label: 'Title',
            width: 200
        }
    }
};
