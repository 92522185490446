"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mockBubles = void 0;
exports.mockBubles = {
    slider: {
        title: 'Maximum 21 hits',
        subtitle: 'Maximum 26 hits',
        backgroundImageURL: 'Customizible color',
        logoImageURL: '.png with transparent BG',
        image: 'Max product image width 304px. <br/> Best dimension of image 604px (size x2)',
        missHitLink: 'This is where the user will land by clicking on any surface out of the space where the product image is displayed and should include tracking parameters such as utm.'
    },
    layout: {
        title: 'Maximum 21 hits',
        subtitle: 'Maximum 26 hits',
        backgroundImageURL: `
      Max desktop background: 485x250px.<br/>
      Max mobile background width: depends of device width<br/>
      Max mobile background height: 125px`,
        logoImageURL: `.png with transparent BG`,
        image: `
      Max product image width: 212px.<br/>
      Max product image height: 188px`,
        missHitLink: 'This is where the user will land by clicking on any surface out of the space where the product image is displayed and should include tracking parameters such as utm.'
    },
    layout_multi: {
        title: 'Maximum 21 hits',
        subtitle: 'Maximum 26 hits',
        backgroundImageURL: `
      Max desktop background: 485x250px.<br/>
      Max mobile background width: depends of device width<br/>
      Max mobile background height: 125px`,
        logoImageURL: `.png with transparent BG`,
        image: `
      Max product image width: 98px.<br/>
      Max product image height: 66px`,
        missHitLink: 'This is where the user will land by clicking on any surface out of the space where the product image is displayed and should include tracking parameters such as utm.'
    },
    integrated_standard: {
        title: 'Maximum 65 hits',
        subtitle: 'Maximum 55 hits',
        backgroundImageURL: `
      Max desktop background: 795x435px.<br/>
      Max mobile background width adjustable depends on the device width it's displayed on<br/>
      Max mobile background height: 190px`,
        logoImageURL: `.png with transparent BG`,
        image: `
      Max product image width: 260px.<br/>
      Max product image height: 200px`,
        missHitLink: 'This is where the user will land by clicking on any surface out of the space where the product image is displayed and should include tracking parameters such as utm.'
    },
    grids: {
        title: `
      Maximum TOTAL copy coverage: 90 hits.<br/>
      Split by <b>TITLE</b> (Bold) & <b>SUBTITLE</b> (Regular)`,
        subtitle: `
      Maximum TOTAL copy coverage: 90 hits.<br/>
      Split by <b>TITLE</b> (Bold) & <b>SUBTITLE</b> (Regular)`,
        backgroundImageURL: `
      Max desktop background: 460x250px.<br/>
      Max mobile background width adjustable depends on the device width it's displayed on<br/>
      Max mobile background height: 130px<br/>
      Background images use fill mode, so about 20% of content from both sides can be excluded from the lay out`,
        logoImageURL: `.png with transparent BG`,
        image: `
      Max product image width: 205px.<br/>
      Max product image height: 250px`,
        missHitLink: 'This is where the user will land by clicking on any surface out of the space where the product image is displayed and should include tracking parameters such as utm.'
    },
    dynamic_slider: {
        title: `1 line: 17 hits`,
        subtitle: `1 line: 14 hits`,
        backgroundImageURL: `
      Background 187x250px when OPEN<br/>
      Background 748x250px when CLOSED<br/> 
      Only 25% of the BG image is visible when the widget is closed. Image focus selection can be on the top/center/bottom`,
        logoImageURL: `No logo`,
        image: `
      Max product image width: 132px.<br/>
      Max product image height: 158px`,
        missHitLink: 'This is where the user will land by clicking on any surface out of the space where the product image is displayed and should include tracking parameters such as utm.'
    },
    immersion: {
        title: `
      Maximum TOTAL copy coverage: 100 hits.<br/>
      Split by <b>TITLE</b> (Bold) & <b>SUBTITLE</b> (Regular)`,
        subtitle: `
      Maximum TOTAL copy coverage: 100 hits.<br/>
      Split by <b>TITLE</b> (Bold) & <b>SUBTITLE</b> (Regular)`,
        backgroundImageURL: `
      Max desktop image width 970px<br/>
      Max desktop image height 250px<br/>
      </br>
      Max mobile image width 300px<br/>
      Max mobile image height 250px<br/>`,
        logoImageURL: `.png with transparent BG`,
        image: `
      Max product image width: 260px.<br/>
      Max product image height: 138px`,
        missHitLink: 'This is where the user will land by clicking on any surface out of the space where the product image is displayed and should include tracking parameters such as utm.'
    },
    overlay: {
        title: `
      Maximum TOTAL copy coverage: 60 hits.<br/>
      Split by <b>TITLE</b> (Bold) & <b>SUBTITLE</b> (Regular)`,
        subtitle: `
      Maximum TOTAL copy coverage: 60 hits.<br/>
      Split by <b>TITLE</b> (Bold) & <b>SUBTITLE</b> (Regular)`,
        backgroundImageURL: `
      Max desktop background: 747x250px.<br/>
      Max mobile background width depends of the device width<br/>
      Max mobile background height: 250px<br/>
      Background images use fill mode, so you can lose about 20% of content from different sides`,
        logoImageURL: `.png with transparent BG`,
        image: `
      Max product image width: 220px.<br/>
      Max product image height: 210px`,
        missHitLink: 'This is where the user will land by clicking on any surface out of the space where the product image is displayed and should include tracking parameters such as utm.'
    }
};
