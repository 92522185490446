"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_class_component_1 = require("vue-class-component");
const image_1 = require("../../utils/image");
class Props {
    constructor() {
        this.product = (0, vue_class_component_1.prop)({ type: Object });
        this.showLabel = (0, vue_class_component_1.prop)({ type: Boolean, default: true });
    }
}
let ProductCard = class ProductCard extends vue_class_component_1.Vue.with(Props) {
    // @Prop() product!: Product
    imageWrapper(url) {
        return (0, image_1.vitrinaImage)(url);
    }
};
ProductCard = __decorate([
    (0, vue_class_component_1.Options)({})
], ProductCard);
exports.default = ProductCard;
