"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableOptions = void 0;
const enums_1 = require("../../types/enums");
exports.tableOptions = {
    tableName: 'Tags',
    action: {
        label: 'Add Tag',
        route: ''
    },
    operations: {
        save: true,
        delete: true
    },
    columns: {
        name: {
            type: enums_1.FieldType.string,
            label: 'Name',
            width: 200
        },
    }
};
