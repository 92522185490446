"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vitrinaImage = void 0;
function vitrinaImage(url) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (url === null || url === void 0 ? void 0 : url.startsWith(document.config.vitrinaHost)) {
        return url;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let res = new URL(document.config.vitrinaHost);
    res.searchParams.append('url', url);
    return res;
}
exports.vitrinaImage = vitrinaImage;
