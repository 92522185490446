"use strict";
// import { Component, Vue } from 'vue-property-decorator';
// import {  Vue } from 'vue-property-decorator';
// import  Component from 'vue-class-component';
Object.defineProperty(exports, "__esModule", { value: true });
const vue_class_component_1 = require("vue-class-component");
// import Vue from 'vue'
// import Component from 'vue-class-component'
// import Options from 'vue-class-component'
// import * as Vue from 'vue'
// @Optionsz
// @Component
class AuthLayout extends vue_class_component_1.Vue {
}
exports.default = AuthLayout;
