"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productsMock = exports.formDataBackgroundMock = void 0;
exports.formDataBackgroundMock = {
    backgroundZoom: 100,
    backgroundZoomMobile: 100
};
exports.productsMock = [
    {
        brand: "L'AUTRECHOSE",
        image: 'https://vitrina.cools.tech?h=370&w=246&fit=inside&we&il&trim=2&url=https://cdn.lautrechose.filoblu.com/media/catalog/product/cache/8183e10c3d534956d51c7d67f9d7d311/B/2/B2511018022U479_03.jpg',
        product_id: 'c209dcfd5996fa7660005e36cd5fac99db75238646d7173267f49b0a',
        salePrice: 195,
        product_slug: 'ABITO-MIDI-IN-CREPE-5e408b26bb7b',
        retailPrice: 390
    },
    {
        brand: "L'AUTRECHOSE",
        image: 'https://vitrina.cools.tech?h=370&w=246&fit=inside&we&il&trim=2&url=https://cdn.lautrechose.filoblu.com/media/catalog/product/cache/8183e10c3d534956d51c7d67f9d7d311/B/2/B2540553023F400_03.jpg',
        product_id: '0791a7d0937714e146c4c00d4e39cfec0059eef7aed94f6c57f1f9d5',
        salePrice: 160,
        product_slug: 'MAXI-GONNA-FANTASIA-92b81b3d15fa',
        retailPrice: 320
    },
    {
        brand: "L'AUTRECHOSE",
        image: 'https://vitrina.cools.tech?h=370&w=246&fit=inside&we&il&trim=2&url=https://cdn.lautrechose.filoblu.com/media/catalog/product/cache/8183e10c3d534956d51c7d67f9d7d311/B/2/B2320164004U999_03.jpg',
        product_id: 'af355b34741a2e94e06098d57356f35c8c10361e1df2a1d141730418',
        salePrice: 118,
        product_slug: 'TOP-IN-SETA-COLOR-NERO-00402ecc6abe',
        retailPrice: 235
    },
    {
        brand: "L'AUTRECHOSE",
        image: 'https://vitrina.cools.tech?h=370&w=246&fit=inside&we&il&trim=2&url=https://cdn.lautrechose.filoblu.com/media/catalog/product/cache/8183e10c3d534956d51c7d67f9d7d311/B/2/B2540549022U479_03.jpg',
        product_id: '7d2ef81942f38effd5ea0e2e36967a2d1542e405caa34766847b3e1f',
        salePrice: 155,
        product_slug: 'GONNA-CON-BALZE-E-VOLANTS-2e7023db4df8',
        retailPrice: 310
    },
    {
        brand: "L'AUTRECHOSE",
        image: 'https://vitrina.cools.tech?h=370&w=246&fit=inside&we&il&trim=2&url=https://cdn.lautrechose.filoblu.com/media/catalog/product/cache/8183e10c3d534956d51c7d67f9d7d311/B/2/B2520588023F400_03.jpg',
        product_id: '5b528b34f9bee6910e2a0536997f5c1c33ad54e2c0bf7df42acc1697',
        salePrice: 120,
        product_slug: 'BLUSA-IN-COTONE-FANTASIA-5e3449e86f26',
        retailPrice: 240
    },
    {
        brand: "L'AUTRECHOSE",
        image: 'https://vitrina.cools.tech?h=370&w=246&fit=inside&we&il&trim=2&url=https://cdn.lautrechose.filoblu.com/media/catalog/product/cache/8183e10c3d534956d51c7d67f9d7d311/L/D/LDN009.75CP26153036_03.jpg',
        product_id: '2a1821488d0d5d8c77e4a6cc314ff04838165c324d2a863134037d73',
        salePrice: 156,
        product_slug: 'SANDALO-CON-TACCO-VIRGOLA-ff4319f06a89',
        retailPrice: 260
    },
    {
        brand: "L'AUTRECHOSE",
        image: 'https://vitrina.cools.tech?h=370&w=246&fit=inside&we&il&trim=2&url=https://cdn.lautrechose.filoblu.com/media/catalog/product/cache/8183e10c3d534956d51c7d67f9d7d311/B/2/B2511031004U999_03.jpg',
        product_id: '35a16ac6216158780d8a6677c71195cc79db282e0223a81fc77364fe',
        salePrice: 300,
        product_slug: 'Assistenza-ABITO-CON-BALZE-IN-SETA-a4615544455d',
        retailPrice: 600
    },
    {
        brand: "L'AUTRECHOSE",
        image: 'https://vitrina.cools.tech?h=370&w=246&fit=inside&we&il&trim=2&url=https://cdn.lautrechose.filoblu.com/media/catalog/product/cache/8183e10c3d534956d51c7d67f9d7d311/L/D/LDN009.95CP3056G344_03.jpg',
        product_id: 'ab6ee29fa771d55f6608c37ca868050a98bb653ceed383e2c4dfc81a',
        salePrice: 145,
        product_slug: 'SANDALO-FANTASIA-TOMATO-4efafbc40655',
        retailPrice: 290
    }
];
